// automatically generated by the FlatBuffers compiler, do not modify

/**
 * @const
 * @namespace
 */
var io = io || {};

/**
 * @const
 * @namespace
 */
io.testable = io.testable || {};

/**
 * @const
 * @namespace
 */
io.testable.agent = io.testable.agent || {};

/**
 * @enum {number}
 */
io.testable.agent.AgentToServerEventType = {
  Register: 0,
  Unregister: 1,
  ScriptExecuted: 2,
  Results: 3,
  Log: 4,
  Ping: 5,
  ChunkStarted: 6,
  ChunkExecuted: 7,
  ChunkFailed: 8,
  ChunkProgress: 9,
  ConcurrentClientStarted: 10,
  ConcurrentClientFinished: 11,
  WillRestart: 12,
  VncStarted: 13
};

/**
 * @enum {string}
 */
io.testable.agent.AgentToServerEventTypeName = {
  '0': 'Register',
  '1': 'Unregister',
  '2': 'ScriptExecuted',
  '3': 'Results',
  '4': 'Log',
  '5': 'Ping',
  '6': 'ChunkStarted',
  '7': 'ChunkExecuted',
  '8': 'ChunkFailed',
  '9': 'ChunkProgress',
  '10': 'ConcurrentClientStarted',
  '11': 'ConcurrentClientFinished',
  '12': 'WillRestart',
  '13': 'VncStarted'
};

/**
 * @enum {number}
 */
io.testable.agent.ServerToAgentEventType = {
  StartChunk: 0,
  StopChunk: 1,
  Registered: 2,
  Error: 3,
  RestartAck: 4,
  UpdateChunk: 5,
  AssignToRegion: 6,
  SendEvent: 7,
  ChunkExecutedAck: 8,
  Heartbeat: 9,
  SendHttpNotification: 10
};

/**
 * @enum {string}
 */
io.testable.agent.ServerToAgentEventTypeName = {
  '0': 'StartChunk',
  '1': 'StopChunk',
  '2': 'Registered',
  '3': 'Error',
  '4': 'RestartAck',
  '5': 'UpdateChunk',
  '6': 'AssignToRegion',
  '7': 'SendEvent',
  '8': 'ChunkExecutedAck',
  '9': 'Heartbeat',
  '10': 'SendHttpNotification'
};

/**
 * @enum {number}
 */
io.testable.agent.ChunkExecutionType = {
  Init: 0,
  Teardown: 1,
  Main: 2
};

/**
 * @enum {string}
 */
io.testable.agent.ChunkExecutionTypeName = {
  '0': 'Init',
  '1': 'Teardown',
  '2': 'Main'
};

/**
 * @enum {number}
 */
io.testable.agent.MetricType = {
  Timing: 0,
  Counter: 1,
  Histogram: 2,
  Metered: 3
};

/**
 * @enum {string}
 */
io.testable.agent.MetricTypeName = {
  '0': 'Timing',
  '1': 'Counter',
  '2': 'Histogram',
  '3': 'Metered'
};

/**
 * @enum {number}
 */
io.testable.agent.LogLevel = {
  Error: 0,
  Info: 1,
  Debug: 2,
  Fatal: 3,
  Trace: 4
};

/**
 * @enum {string}
 */
io.testable.agent.LogLevelName = {
  '0': 'Error',
  '1': 'Info',
  '2': 'Debug',
  '3': 'Fatal',
  '4': 'Trace'
};

/**
 * @enum {number}
 */
io.testable.agent.TestType = {
  Load: 0,
  Functional: 1,
  Monitor: 2,
  Remote: 3
};

/**
 * @enum {string}
 */
io.testable.agent.TestTypeName = {
  '0': 'Load',
  '1': 'Functional',
  '2': 'Monitor',
  '3': 'Remote'
};

/**
 * @enum {number}
 */
io.testable.agent.TestState = {
  Passed: 0,
  Failed: 1,
  Skipped: 2,
  NA: 3,
  InProgress: 4,
  Retried: 5,
  Delete: 6
};

/**
 * @enum {string}
 */
io.testable.agent.TestStateName = {
  '0': 'Passed',
  '1': 'Failed',
  '2': 'Skipped',
  '3': 'NA',
  '4': 'InProgress',
  '5': 'Retried',
  '6': 'Delete'
};

/**
 * @enum {number}
 */
io.testable.agent.ChunkOutcome = {
  None: 0,
  Passed: 1,
  Failed: 2,
  Timeout: 3
};

/**
 * @enum {string}
 */
io.testable.agent.ChunkOutcomeName = {
  '0': 'None',
  '1': 'Passed',
  '2': 'Failed',
  '3': 'Timeout'
};

/**
 * @enum {number}
 */
io.testable.agent.AgentSuiteActionType = {
  StartSuite: 0,
  FinishSuite: 1,
  StartSuiteTest: 2,
  FinishSuiteTest: 3
};

/**
 * @enum {string}
 */
io.testable.agent.AgentSuiteActionTypeName = {
  '0': 'StartSuite',
  '1': 'FinishSuite',
  '2': 'StartSuiteTest',
  '3': 'FinishSuiteTest'
};

/**
 * @enum {number}
 */
io.testable.agent.AgentCommandActionType = {
  StartStream: 0,
  FinishStream: 1,
  StartCommand: 2,
  FinishCommand: 3
};

/**
 * @enum {string}
 */
io.testable.agent.AgentCommandActionTypeName = {
  '0': 'StartStream',
  '1': 'FinishStream',
  '2': 'StartCommand',
  '3': 'FinishCommand'
};

/**
 * @constructor
 */
io.testable.agent.AgentToServerEvent = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentToServerEvent}
 */
io.testable.agent.AgentToServerEvent.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentToServerEvent=} obj
 * @returns {io.testable.agent.AgentToServerEvent}
 */
io.testable.agent.AgentToServerEvent.getRootAsAgentToServerEvent = function(bb, obj) {
  return (obj || new io.testable.agent.AgentToServerEvent).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentToServerEvent=} obj
 * @returns {io.testable.agent.AgentToServerEvent}
 */
io.testable.agent.AgentToServerEvent.getSizePrefixedRootAsAgentToServerEvent = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentToServerEvent).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {io.testable.agent.AgentToServerEventType}
 */
io.testable.agent.AgentToServerEvent.prototype.eventType = function() {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? /** @type {io.testable.agent.AgentToServerEventType} */ (this.bb.readInt16(this.bb_pos + offset)) : io.testable.agent.AgentToServerEventType.Register;
};

/**
 * @param {io.testable.agent.AgentHealth=} obj
 * @returns {io.testable.agent.AgentHealth|null}
 */
io.testable.agent.AgentToServerEvent.prototype.health = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? (obj || new io.testable.agent.AgentHealth).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {io.testable.agent.AgentStatus=} obj
 * @returns {io.testable.agent.AgentStatus|null}
 */
io.testable.agent.AgentToServerEvent.prototype.status = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? (obj || new io.testable.agent.AgentStatus).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {io.testable.agent.AgentChunkResults=} obj
 * @returns {io.testable.agent.AgentChunkResults|null}
 */
io.testable.agent.AgentToServerEvent.prototype.results = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? (obj || new io.testable.agent.AgentChunkResults).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {io.testable.agent.ChunkInfo=} obj
 * @returns {io.testable.agent.ChunkInfo|null}
 */
io.testable.agent.AgentToServerEvent.prototype.chunk = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? (obj || new io.testable.agent.ChunkInfo).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {io.testable.agent.AgentConcurrentClient=} obj
 * @returns {io.testable.agent.AgentConcurrentClient|null}
 */
io.testable.agent.AgentToServerEvent.prototype.concurrentClient = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? (obj || new io.testable.agent.AgentConcurrentClient).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {io.testable.agent.AgentChunkProgress=} obj
 * @returns {io.testable.agent.AgentChunkProgress|null}
 */
io.testable.agent.AgentToServerEvent.prototype.chunkProgress = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 16);
  return offset ? (obj || new io.testable.agent.AgentChunkProgress).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentToServerEvent.prototype.startedAt = function() {
  var offset = this.bb.__offset(this.bb_pos, 18);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @param {io.testable.agent.AgentLog=} obj
 * @returns {io.testable.agent.AgentLog|null}
 */
io.testable.agent.AgentToServerEvent.prototype.log = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 20);
  return offset ? (obj || new io.testable.agent.AgentLog).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentToServerEvent.prototype.correlationId = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 22);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentToServerEvent.prototype.iterationsExecuted = function() {
  var offset = this.bb.__offset(this.bb_pos, 24);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentToServerEvent.prototype.failureMessage = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 26);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {boolean}
 */
io.testable.agent.AgentToServerEvent.prototype.isExpectedRestart = function() {
  var offset = this.bb.__offset(this.bb_pos, 28);
  return offset ? !!this.bb.readInt8(this.bb_pos + offset) : false;
};

/**
 * @param {io.testable.agent.AgentVncInfo=} obj
 * @returns {io.testable.agent.AgentVncInfo|null}
 */
io.testable.agent.AgentToServerEvent.prototype.vncInfo = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 30);
  return offset ? (obj || new io.testable.agent.AgentVncInfo).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentToServerEvent.startAgentToServerEvent = function(builder) {
  builder.startObject(14);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {io.testable.agent.AgentToServerEventType} eventType
 */
io.testable.agent.AgentToServerEvent.addEventType = function(builder, eventType) {
  builder.addFieldInt16(0, eventType, io.testable.agent.AgentToServerEventType.Register);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} healthOffset
 */
io.testable.agent.AgentToServerEvent.addHealth = function(builder, healthOffset) {
  builder.addFieldOffset(1, healthOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} statusOffset
 */
io.testable.agent.AgentToServerEvent.addStatus = function(builder, statusOffset) {
  builder.addFieldOffset(2, statusOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} resultsOffset
 */
io.testable.agent.AgentToServerEvent.addResults = function(builder, resultsOffset) {
  builder.addFieldOffset(3, resultsOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} chunkOffset
 */
io.testable.agent.AgentToServerEvent.addChunk = function(builder, chunkOffset) {
  builder.addFieldOffset(4, chunkOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} concurrentClientOffset
 */
io.testable.agent.AgentToServerEvent.addConcurrentClient = function(builder, concurrentClientOffset) {
  builder.addFieldOffset(5, concurrentClientOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} chunkProgressOffset
 */
io.testable.agent.AgentToServerEvent.addChunkProgress = function(builder, chunkProgressOffset) {
  builder.addFieldOffset(6, chunkProgressOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} startedAt
 */
io.testable.agent.AgentToServerEvent.addStartedAt = function(builder, startedAt) {
  builder.addFieldInt64(7, startedAt, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} logOffset
 */
io.testable.agent.AgentToServerEvent.addLog = function(builder, logOffset) {
  builder.addFieldOffset(8, logOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} correlationIdOffset
 */
io.testable.agent.AgentToServerEvent.addCorrelationId = function(builder, correlationIdOffset) {
  builder.addFieldOffset(9, correlationIdOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} iterationsExecuted
 */
io.testable.agent.AgentToServerEvent.addIterationsExecuted = function(builder, iterationsExecuted) {
  builder.addFieldInt64(10, iterationsExecuted, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} failureMessageOffset
 */
io.testable.agent.AgentToServerEvent.addFailureMessage = function(builder, failureMessageOffset) {
  builder.addFieldOffset(11, failureMessageOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {boolean} isExpectedRestart
 */
io.testable.agent.AgentToServerEvent.addIsExpectedRestart = function(builder, isExpectedRestart) {
  builder.addFieldInt8(12, +isExpectedRestart, +false);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} vncInfoOffset
 */
io.testable.agent.AgentToServerEvent.addVncInfo = function(builder, vncInfoOffset) {
  builder.addFieldOffset(13, vncInfoOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentToServerEvent.endAgentToServerEvent = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {io.testable.agent.AgentToServerEventType} eventType
 * @param {flatbuffers.Offset} healthOffset
 * @param {flatbuffers.Offset} statusOffset
 * @param {flatbuffers.Offset} resultsOffset
 * @param {flatbuffers.Offset} chunkOffset
 * @param {flatbuffers.Offset} concurrentClientOffset
 * @param {flatbuffers.Offset} chunkProgressOffset
 * @param {flatbuffers.Long} startedAt
 * @param {flatbuffers.Offset} logOffset
 * @param {flatbuffers.Offset} correlationIdOffset
 * @param {flatbuffers.Long} iterationsExecuted
 * @param {flatbuffers.Offset} failureMessageOffset
 * @param {boolean} isExpectedRestart
 * @param {flatbuffers.Offset} vncInfoOffset
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentToServerEvent.createAgentToServerEvent = function(builder, eventType, healthOffset, statusOffset, resultsOffset, chunkOffset, concurrentClientOffset, chunkProgressOffset, startedAt, logOffset, correlationIdOffset, iterationsExecuted, failureMessageOffset, isExpectedRestart, vncInfoOffset) {
  io.testable.agent.AgentToServerEvent.startAgentToServerEvent(builder);
  io.testable.agent.AgentToServerEvent.addEventType(builder, eventType);
  io.testable.agent.AgentToServerEvent.addHealth(builder, healthOffset);
  io.testable.agent.AgentToServerEvent.addStatus(builder, statusOffset);
  io.testable.agent.AgentToServerEvent.addResults(builder, resultsOffset);
  io.testable.agent.AgentToServerEvent.addChunk(builder, chunkOffset);
  io.testable.agent.AgentToServerEvent.addConcurrentClient(builder, concurrentClientOffset);
  io.testable.agent.AgentToServerEvent.addChunkProgress(builder, chunkProgressOffset);
  io.testable.agent.AgentToServerEvent.addStartedAt(builder, startedAt);
  io.testable.agent.AgentToServerEvent.addLog(builder, logOffset);
  io.testable.agent.AgentToServerEvent.addCorrelationId(builder, correlationIdOffset);
  io.testable.agent.AgentToServerEvent.addIterationsExecuted(builder, iterationsExecuted);
  io.testable.agent.AgentToServerEvent.addFailureMessage(builder, failureMessageOffset);
  io.testable.agent.AgentToServerEvent.addIsExpectedRestart(builder, isExpectedRestart);
  io.testable.agent.AgentToServerEvent.addVncInfo(builder, vncInfoOffset);
  return io.testable.agent.AgentToServerEvent.endAgentToServerEvent(builder);
}

/**
 * @constructor
 */
io.testable.agent.ServerToAgentEvent = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.ServerToAgentEvent}
 */
io.testable.agent.ServerToAgentEvent.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.ServerToAgentEvent=} obj
 * @returns {io.testable.agent.ServerToAgentEvent}
 */
io.testable.agent.ServerToAgentEvent.getRootAsServerToAgentEvent = function(bb, obj) {
  return (obj || new io.testable.agent.ServerToAgentEvent).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.ServerToAgentEvent=} obj
 * @returns {io.testable.agent.ServerToAgentEvent}
 */
io.testable.agent.ServerToAgentEvent.getSizePrefixedRootAsServerToAgentEvent = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.ServerToAgentEvent).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {io.testable.agent.ServerToAgentEventType}
 */
io.testable.agent.ServerToAgentEvent.prototype.eventType = function() {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? /** @type {io.testable.agent.ServerToAgentEventType} */ (this.bb.readInt16(this.bb_pos + offset)) : io.testable.agent.ServerToAgentEventType.StartChunk;
};

/**
 * @returns {boolean}
 */
io.testable.agent.ServerToAgentEvent.prototype.registrationExists = function() {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? !!this.bb.readInt8(this.bb_pos + offset) : false;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.ServerToAgentEvent.prototype.chunk = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.ServerToAgentEvent.prototype.error = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.ServerToAgentEvent.prototype.correlationId = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {boolean}
 */
io.testable.agent.ServerToAgentEvent.prototype.reallocating = function() {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? !!this.bb.readInt8(this.bb_pos + offset) : false;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.ServerToAgentEvent.prototype.chunkId = function() {
  var offset = this.bb.__offset(this.bb_pos, 16);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {boolean}
 */
io.testable.agent.ServerToAgentEvent.prototype.onDemand = function() {
  var offset = this.bb.__offset(this.bb_pos, 18);
  return offset ? !!this.bb.readInt8(this.bb_pos + offset) : false;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.ServerToAgentEvent.prototype.org = function() {
  var offset = this.bb.__offset(this.bb_pos, 20);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.ServerToAgentEvent.prototype.testCase = function() {
  var offset = this.bb.__offset(this.bb_pos, 22);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.ServerToAgentEvent.prototype.execution = function() {
  var offset = this.bb.__offset(this.bb_pos, 24);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.ServerToAgentEvent.prototype.executionRegion = function() {
  var offset = this.bb.__offset(this.bb_pos, 26);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @param {number} index
 * @returns {flatbuffers.Long}
 */
io.testable.agent.ServerToAgentEvent.prototype.unexpectedActiveChunks = function(index) {
  var offset = this.bb.__offset(this.bb_pos, 28);
  return offset ? this.bb.readInt64(this.bb.__vector(this.bb_pos + offset) + index * 8) : this.bb.createLong(0, 0);
};

/**
 * @returns {number}
 */
io.testable.agent.ServerToAgentEvent.prototype.unexpectedActiveChunksLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 28);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.ServerToAgentEvent.prototype.update = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 30);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {io.testable.agent.AssignedRegionInfo=} obj
 * @returns {io.testable.agent.AssignedRegionInfo|null}
 */
io.testable.agent.ServerToAgentEvent.prototype.assignedRegionInfo = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 32);
  return offset ? (obj || new io.testable.agent.AssignedRegionInfo).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {io.testable.agent.TestEvent=} obj
 * @returns {io.testable.agent.TestEvent|null}
 */
io.testable.agent.ServerToAgentEvent.prototype.event = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 34);
  return offset ? (obj || new io.testable.agent.TestEvent).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.ServerToAgentEvent.prototype.key = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 36);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.ServerToAgentEvent.prototype.region = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 38);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.ServerToAgentEvent.prototype.s3AccessKeyId = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 40);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.ServerToAgentEvent.prototype.s3SecretAccessKey = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 42);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {number} index
 * @param {io.testable.agent.AssignedRegionInfo=} obj
 * @returns {io.testable.agent.AssignedRegionInfo}
 */
io.testable.agent.ServerToAgentEvent.prototype.assignedRegionInfos = function(index, obj) {
  var offset = this.bb.__offset(this.bb_pos, 44);
  return offset ? (obj || new io.testable.agent.AssignedRegionInfo).__init(this.bb.__indirect(this.bb.__vector(this.bb_pos + offset) + index * 4), this.bb) : null;
};

/**
 * @returns {number}
 */
io.testable.agent.ServerToAgentEvent.prototype.assignedRegionInfosLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 44);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.ServerToAgentEvent.prototype.notification = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 46);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.ServerToAgentEvent.startServerToAgentEvent = function(builder) {
  builder.startObject(22);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {io.testable.agent.ServerToAgentEventType} eventType
 */
io.testable.agent.ServerToAgentEvent.addEventType = function(builder, eventType) {
  builder.addFieldInt16(0, eventType, io.testable.agent.ServerToAgentEventType.StartChunk);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {boolean} registrationExists
 */
io.testable.agent.ServerToAgentEvent.addRegistrationExists = function(builder, registrationExists) {
  builder.addFieldInt8(1, +registrationExists, +false);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} chunkOffset
 */
io.testable.agent.ServerToAgentEvent.addChunk = function(builder, chunkOffset) {
  builder.addFieldOffset(2, chunkOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} errorOffset
 */
io.testable.agent.ServerToAgentEvent.addError = function(builder, errorOffset) {
  builder.addFieldOffset(3, errorOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} correlationIdOffset
 */
io.testable.agent.ServerToAgentEvent.addCorrelationId = function(builder, correlationIdOffset) {
  builder.addFieldOffset(4, correlationIdOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {boolean} reallocating
 */
io.testable.agent.ServerToAgentEvent.addReallocating = function(builder, reallocating) {
  builder.addFieldInt8(5, +reallocating, +false);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} chunkId
 */
io.testable.agent.ServerToAgentEvent.addChunkId = function(builder, chunkId) {
  builder.addFieldInt64(6, chunkId, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {boolean} onDemand
 */
io.testable.agent.ServerToAgentEvent.addOnDemand = function(builder, onDemand) {
  builder.addFieldInt8(7, +onDemand, +false);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} org
 */
io.testable.agent.ServerToAgentEvent.addOrg = function(builder, org) {
  builder.addFieldInt64(8, org, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} testCase
 */
io.testable.agent.ServerToAgentEvent.addTestCase = function(builder, testCase) {
  builder.addFieldInt64(9, testCase, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} execution
 */
io.testable.agent.ServerToAgentEvent.addExecution = function(builder, execution) {
  builder.addFieldInt64(10, execution, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} executionRegion
 */
io.testable.agent.ServerToAgentEvent.addExecutionRegion = function(builder, executionRegion) {
  builder.addFieldInt64(11, executionRegion, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} unexpectedActiveChunksOffset
 */
io.testable.agent.ServerToAgentEvent.addUnexpectedActiveChunks = function(builder, unexpectedActiveChunksOffset) {
  builder.addFieldOffset(12, unexpectedActiveChunksOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Long>} data
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.ServerToAgentEvent.createUnexpectedActiveChunksVector = function(builder, data) {
  builder.startVector(8, data.length, 8);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addInt64(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
io.testable.agent.ServerToAgentEvent.startUnexpectedActiveChunksVector = function(builder, numElems) {
  builder.startVector(8, numElems, 8);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} updateOffset
 */
io.testable.agent.ServerToAgentEvent.addUpdate = function(builder, updateOffset) {
  builder.addFieldOffset(13, updateOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} assignedRegionInfoOffset
 */
io.testable.agent.ServerToAgentEvent.addAssignedRegionInfo = function(builder, assignedRegionInfoOffset) {
  builder.addFieldOffset(14, assignedRegionInfoOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} eventOffset
 */
io.testable.agent.ServerToAgentEvent.addEvent = function(builder, eventOffset) {
  builder.addFieldOffset(15, eventOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} keyOffset
 */
io.testable.agent.ServerToAgentEvent.addKey = function(builder, keyOffset) {
  builder.addFieldOffset(16, keyOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} regionOffset
 */
io.testable.agent.ServerToAgentEvent.addRegion = function(builder, regionOffset) {
  builder.addFieldOffset(17, regionOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} s3AccessKeyIdOffset
 */
io.testable.agent.ServerToAgentEvent.addS3AccessKeyId = function(builder, s3AccessKeyIdOffset) {
  builder.addFieldOffset(18, s3AccessKeyIdOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} s3SecretAccessKeyOffset
 */
io.testable.agent.ServerToAgentEvent.addS3SecretAccessKey = function(builder, s3SecretAccessKeyOffset) {
  builder.addFieldOffset(19, s3SecretAccessKeyOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} assignedRegionInfosOffset
 */
io.testable.agent.ServerToAgentEvent.addAssignedRegionInfos = function(builder, assignedRegionInfosOffset) {
  builder.addFieldOffset(20, assignedRegionInfosOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.ServerToAgentEvent.createAssignedRegionInfosVector = function(builder, data) {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
io.testable.agent.ServerToAgentEvent.startAssignedRegionInfosVector = function(builder, numElems) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} notificationOffset
 */
io.testable.agent.ServerToAgentEvent.addNotification = function(builder, notificationOffset) {
  builder.addFieldOffset(21, notificationOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.ServerToAgentEvent.endServerToAgentEvent = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {io.testable.agent.ServerToAgentEventType} eventType
 * @param {boolean} registrationExists
 * @param {flatbuffers.Offset} chunkOffset
 * @param {flatbuffers.Offset} errorOffset
 * @param {flatbuffers.Offset} correlationIdOffset
 * @param {boolean} reallocating
 * @param {flatbuffers.Long} chunkId
 * @param {boolean} onDemand
 * @param {flatbuffers.Long} org
 * @param {flatbuffers.Long} testCase
 * @param {flatbuffers.Long} execution
 * @param {flatbuffers.Long} executionRegion
 * @param {flatbuffers.Offset} unexpectedActiveChunksOffset
 * @param {flatbuffers.Offset} updateOffset
 * @param {flatbuffers.Offset} assignedRegionInfoOffset
 * @param {flatbuffers.Offset} eventOffset
 * @param {flatbuffers.Offset} keyOffset
 * @param {flatbuffers.Offset} regionOffset
 * @param {flatbuffers.Offset} s3AccessKeyIdOffset
 * @param {flatbuffers.Offset} s3SecretAccessKeyOffset
 * @param {flatbuffers.Offset} assignedRegionInfosOffset
 * @param {flatbuffers.Offset} notificationOffset
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.ServerToAgentEvent.createServerToAgentEvent = function(builder, eventType, registrationExists, chunkOffset, errorOffset, correlationIdOffset, reallocating, chunkId, onDemand, org, testCase, execution, executionRegion, unexpectedActiveChunksOffset, updateOffset, assignedRegionInfoOffset, eventOffset, keyOffset, regionOffset, s3AccessKeyIdOffset, s3SecretAccessKeyOffset, assignedRegionInfosOffset, notificationOffset) {
  io.testable.agent.ServerToAgentEvent.startServerToAgentEvent(builder);
  io.testable.agent.ServerToAgentEvent.addEventType(builder, eventType);
  io.testable.agent.ServerToAgentEvent.addRegistrationExists(builder, registrationExists);
  io.testable.agent.ServerToAgentEvent.addChunk(builder, chunkOffset);
  io.testable.agent.ServerToAgentEvent.addError(builder, errorOffset);
  io.testable.agent.ServerToAgentEvent.addCorrelationId(builder, correlationIdOffset);
  io.testable.agent.ServerToAgentEvent.addReallocating(builder, reallocating);
  io.testable.agent.ServerToAgentEvent.addChunkId(builder, chunkId);
  io.testable.agent.ServerToAgentEvent.addOnDemand(builder, onDemand);
  io.testable.agent.ServerToAgentEvent.addOrg(builder, org);
  io.testable.agent.ServerToAgentEvent.addTestCase(builder, testCase);
  io.testable.agent.ServerToAgentEvent.addExecution(builder, execution);
  io.testable.agent.ServerToAgentEvent.addExecutionRegion(builder, executionRegion);
  io.testable.agent.ServerToAgentEvent.addUnexpectedActiveChunks(builder, unexpectedActiveChunksOffset);
  io.testable.agent.ServerToAgentEvent.addUpdate(builder, updateOffset);
  io.testable.agent.ServerToAgentEvent.addAssignedRegionInfo(builder, assignedRegionInfoOffset);
  io.testable.agent.ServerToAgentEvent.addEvent(builder, eventOffset);
  io.testable.agent.ServerToAgentEvent.addKey(builder, keyOffset);
  io.testable.agent.ServerToAgentEvent.addRegion(builder, regionOffset);
  io.testable.agent.ServerToAgentEvent.addS3AccessKeyId(builder, s3AccessKeyIdOffset);
  io.testable.agent.ServerToAgentEvent.addS3SecretAccessKey(builder, s3SecretAccessKeyOffset);
  io.testable.agent.ServerToAgentEvent.addAssignedRegionInfos(builder, assignedRegionInfosOffset);
  io.testable.agent.ServerToAgentEvent.addNotification(builder, notificationOffset);
  return io.testable.agent.ServerToAgentEvent.endServerToAgentEvent(builder);
}

/**
 * @constructor
 */
io.testable.agent.TestEvent = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.TestEvent}
 */
io.testable.agent.TestEvent.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.TestEvent=} obj
 * @returns {io.testable.agent.TestEvent}
 */
io.testable.agent.TestEvent.getRootAsTestEvent = function(bb, obj) {
  return (obj || new io.testable.agent.TestEvent).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.TestEvent=} obj
 * @returns {io.testable.agent.TestEvent}
 */
io.testable.agent.TestEvent.getSizePrefixedRootAsTestEvent = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.TestEvent).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.TestEvent.prototype.name = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.TestEvent.prototype.contents = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.TestEvent.startTestEvent = function(builder) {
  builder.startObject(2);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} nameOffset
 */
io.testable.agent.TestEvent.addName = function(builder, nameOffset) {
  builder.addFieldOffset(0, nameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} contentsOffset
 */
io.testable.agent.TestEvent.addContents = function(builder, contentsOffset) {
  builder.addFieldOffset(1, contentsOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.TestEvent.endTestEvent = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} nameOffset
 * @param {flatbuffers.Offset} contentsOffset
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.TestEvent.createTestEvent = function(builder, nameOffset, contentsOffset) {
  io.testable.agent.TestEvent.startTestEvent(builder);
  io.testable.agent.TestEvent.addName(builder, nameOffset);
  io.testable.agent.TestEvent.addContents(builder, contentsOffset);
  return io.testable.agent.TestEvent.endTestEvent(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentStatus = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentStatus}
 */
io.testable.agent.AgentStatus.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentStatus=} obj
 * @returns {io.testable.agent.AgentStatus}
 */
io.testable.agent.AgentStatus.getRootAsAgentStatus = function(bb, obj) {
  return (obj || new io.testable.agent.AgentStatus).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentStatus=} obj
 * @returns {io.testable.agent.AgentStatus}
 */
io.testable.agent.AgentStatus.getSizePrefixedRootAsAgentStatus = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentStatus).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStatus.prototype.id = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStatus.prototype.key = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {io.testable.agent.RegionInfo=} obj
 * @returns {io.testable.agent.RegionInfo|null}
 */
io.testable.agent.AgentStatus.prototype.region = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? (obj || new io.testable.agent.RegionInfo).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentStatus.prototype.concurrentConnectionLimit = function() {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentStatus.prototype.activeConnections = function() {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStatus.prototype.pid = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStatus.prototype.host = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 16);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStatus.prototype.containerId = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 18);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {io.testable.agent.LocationInfo=} obj
 * @returns {io.testable.agent.LocationInfo|null}
 */
io.testable.agent.AgentStatus.prototype.location = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 20);
  return offset ? (obj || new io.testable.agent.LocationInfo).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentStatus.prototype.version = function() {
  var offset = this.bb.__offset(this.bb_pos, 22);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentStatus.prototype.freeMemory = function() {
  var offset = this.bb.__offset(this.bb_pos, 24);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentStatus.prototype.totalMemory = function() {
  var offset = this.bb.__offset(this.bb_pos, 26);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {number}
 */
io.testable.agent.AgentStatus.prototype.cpu = function() {
  var offset = this.bb.__offset(this.bb_pos, 28);
  return offset ? this.bb.readFloat64(this.bb_pos + offset) : 0.0;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentStatus.prototype.totalCpus = function() {
  var offset = this.bb.__offset(this.bb_pos, 30);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @param {number} index
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentStatus.prototype.activeChunks = function(index) {
  var offset = this.bb.__offset(this.bb_pos, 32);
  return offset ? this.bb.readInt64(this.bb.__vector(this.bb_pos + offset) + index * 8) : this.bb.createLong(0, 0);
};

/**
 * @returns {number}
 */
io.testable.agent.AgentStatus.prototype.activeChunksLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 32);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStatus.prototype.instanceId = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 34);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStatus.prototype.productCode = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 36);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStatus.prototype.agentVersion = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 38);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStatus.prototype.platform = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 40);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {number} index
 * @param {io.testable.agent.Browser=} obj
 * @returns {io.testable.agent.Browser}
 */
io.testable.agent.AgentStatus.prototype.installedBrowsers = function(index, obj) {
  var offset = this.bb.__offset(this.bb_pos, 42);
  return offset ? (obj || new io.testable.agent.Browser).__init(this.bb.__indirect(this.bb.__vector(this.bb_pos + offset) + index * 4), this.bb) : null;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentStatus.prototype.installedBrowsersLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 42);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @returns {boolean}
 */
io.testable.agent.AgentStatus.prototype.requiresLock = function() {
  var offset = this.bb.__offset(this.bb_pos, 44);
  return offset ? !!this.bb.readInt8(this.bb_pos + offset) : false;
};

/**
 * @param {number} index
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array}
 */
io.testable.agent.AgentStatus.prototype.keys = function(index, optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 46);
  return offset ? this.bb.__string(this.bb.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentStatus.prototype.keysLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 46);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @param {number} index
 * @param {io.testable.agent.RegionInfo=} obj
 * @returns {io.testable.agent.RegionInfo}
 */
io.testable.agent.AgentStatus.prototype.regions = function(index, obj) {
  var offset = this.bb.__offset(this.bb_pos, 48);
  return offset ? (obj || new io.testable.agent.RegionInfo).__init(this.bb.__indirect(this.bb.__vector(this.bb_pos + offset) + index * 4), this.bb) : null;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentStatus.prototype.regionsLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 48);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @param {number} index
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array}
 */
io.testable.agent.AgentStatus.prototype.scenarioTypes = function(index, optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 50);
  return offset ? this.bb.__string(this.bb.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentStatus.prototype.scenarioTypesLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 50);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentStatus.startAgentStatus = function(builder) {
  builder.startObject(24);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} idOffset
 */
io.testable.agent.AgentStatus.addId = function(builder, idOffset) {
  builder.addFieldOffset(0, idOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} keyOffset
 */
io.testable.agent.AgentStatus.addKey = function(builder, keyOffset) {
  builder.addFieldOffset(1, keyOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} regionOffset
 */
io.testable.agent.AgentStatus.addRegion = function(builder, regionOffset) {
  builder.addFieldOffset(2, regionOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} concurrentConnectionLimit
 */
io.testable.agent.AgentStatus.addConcurrentConnectionLimit = function(builder, concurrentConnectionLimit) {
  builder.addFieldInt64(3, concurrentConnectionLimit, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} activeConnections
 */
io.testable.agent.AgentStatus.addActiveConnections = function(builder, activeConnections) {
  builder.addFieldInt64(4, activeConnections, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} pidOffset
 */
io.testable.agent.AgentStatus.addPid = function(builder, pidOffset) {
  builder.addFieldOffset(5, pidOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} hostOffset
 */
io.testable.agent.AgentStatus.addHost = function(builder, hostOffset) {
  builder.addFieldOffset(6, hostOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} containerIdOffset
 */
io.testable.agent.AgentStatus.addContainerId = function(builder, containerIdOffset) {
  builder.addFieldOffset(7, containerIdOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} locationOffset
 */
io.testable.agent.AgentStatus.addLocation = function(builder, locationOffset) {
  builder.addFieldOffset(8, locationOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} version
 */
io.testable.agent.AgentStatus.addVersion = function(builder, version) {
  builder.addFieldInt32(9, version, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} freeMemory
 */
io.testable.agent.AgentStatus.addFreeMemory = function(builder, freeMemory) {
  builder.addFieldInt64(10, freeMemory, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} totalMemory
 */
io.testable.agent.AgentStatus.addTotalMemory = function(builder, totalMemory) {
  builder.addFieldInt64(11, totalMemory, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} cpu
 */
io.testable.agent.AgentStatus.addCpu = function(builder, cpu) {
  builder.addFieldFloat64(12, cpu, 0.0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} totalCpus
 */
io.testable.agent.AgentStatus.addTotalCpus = function(builder, totalCpus) {
  builder.addFieldInt32(13, totalCpus, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} activeChunksOffset
 */
io.testable.agent.AgentStatus.addActiveChunks = function(builder, activeChunksOffset) {
  builder.addFieldOffset(14, activeChunksOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Long>} data
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentStatus.createActiveChunksVector = function(builder, data) {
  builder.startVector(8, data.length, 8);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addInt64(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
io.testable.agent.AgentStatus.startActiveChunksVector = function(builder, numElems) {
  builder.startVector(8, numElems, 8);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} instanceIdOffset
 */
io.testable.agent.AgentStatus.addInstanceId = function(builder, instanceIdOffset) {
  builder.addFieldOffset(15, instanceIdOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} productCodeOffset
 */
io.testable.agent.AgentStatus.addProductCode = function(builder, productCodeOffset) {
  builder.addFieldOffset(16, productCodeOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} agentVersionOffset
 */
io.testable.agent.AgentStatus.addAgentVersion = function(builder, agentVersionOffset) {
  builder.addFieldOffset(17, agentVersionOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} platformOffset
 */
io.testable.agent.AgentStatus.addPlatform = function(builder, platformOffset) {
  builder.addFieldOffset(18, platformOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} installedBrowsersOffset
 */
io.testable.agent.AgentStatus.addInstalledBrowsers = function(builder, installedBrowsersOffset) {
  builder.addFieldOffset(19, installedBrowsersOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentStatus.createInstalledBrowsersVector = function(builder, data) {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
io.testable.agent.AgentStatus.startInstalledBrowsersVector = function(builder, numElems) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {boolean} requiresLock
 */
io.testable.agent.AgentStatus.addRequiresLock = function(builder, requiresLock) {
  builder.addFieldInt8(20, +requiresLock, +false);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} keysOffset
 */
io.testable.agent.AgentStatus.addKeys = function(builder, keysOffset) {
  builder.addFieldOffset(21, keysOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentStatus.createKeysVector = function(builder, data) {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
io.testable.agent.AgentStatus.startKeysVector = function(builder, numElems) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} regionsOffset
 */
io.testable.agent.AgentStatus.addRegions = function(builder, regionsOffset) {
  builder.addFieldOffset(22, regionsOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentStatus.createRegionsVector = function(builder, data) {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
io.testable.agent.AgentStatus.startRegionsVector = function(builder, numElems) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} scenarioTypesOffset
 */
io.testable.agent.AgentStatus.addScenarioTypes = function(builder, scenarioTypesOffset) {
  builder.addFieldOffset(23, scenarioTypesOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentStatus.createScenarioTypesVector = function(builder, data) {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
io.testable.agent.AgentStatus.startScenarioTypesVector = function(builder, numElems) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentStatus.endAgentStatus = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} idOffset
 * @param {flatbuffers.Offset} keyOffset
 * @param {flatbuffers.Offset} regionOffset
 * @param {flatbuffers.Long} concurrentConnectionLimit
 * @param {flatbuffers.Long} activeConnections
 * @param {flatbuffers.Offset} pidOffset
 * @param {flatbuffers.Offset} hostOffset
 * @param {flatbuffers.Offset} containerIdOffset
 * @param {flatbuffers.Offset} locationOffset
 * @param {number} version
 * @param {flatbuffers.Long} freeMemory
 * @param {flatbuffers.Long} totalMemory
 * @param {number} cpu
 * @param {number} totalCpus
 * @param {flatbuffers.Offset} activeChunksOffset
 * @param {flatbuffers.Offset} instanceIdOffset
 * @param {flatbuffers.Offset} productCodeOffset
 * @param {flatbuffers.Offset} agentVersionOffset
 * @param {flatbuffers.Offset} platformOffset
 * @param {flatbuffers.Offset} installedBrowsersOffset
 * @param {boolean} requiresLock
 * @param {flatbuffers.Offset} keysOffset
 * @param {flatbuffers.Offset} regionsOffset
 * @param {flatbuffers.Offset} scenarioTypesOffset
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentStatus.createAgentStatus = function(builder, idOffset, keyOffset, regionOffset, concurrentConnectionLimit, activeConnections, pidOffset, hostOffset, containerIdOffset, locationOffset, version, freeMemory, totalMemory, cpu, totalCpus, activeChunksOffset, instanceIdOffset, productCodeOffset, agentVersionOffset, platformOffset, installedBrowsersOffset, requiresLock, keysOffset, regionsOffset, scenarioTypesOffset) {
  io.testable.agent.AgentStatus.startAgentStatus(builder);
  io.testable.agent.AgentStatus.addId(builder, idOffset);
  io.testable.agent.AgentStatus.addKey(builder, keyOffset);
  io.testable.agent.AgentStatus.addRegion(builder, regionOffset);
  io.testable.agent.AgentStatus.addConcurrentConnectionLimit(builder, concurrentConnectionLimit);
  io.testable.agent.AgentStatus.addActiveConnections(builder, activeConnections);
  io.testable.agent.AgentStatus.addPid(builder, pidOffset);
  io.testable.agent.AgentStatus.addHost(builder, hostOffset);
  io.testable.agent.AgentStatus.addContainerId(builder, containerIdOffset);
  io.testable.agent.AgentStatus.addLocation(builder, locationOffset);
  io.testable.agent.AgentStatus.addVersion(builder, version);
  io.testable.agent.AgentStatus.addFreeMemory(builder, freeMemory);
  io.testable.agent.AgentStatus.addTotalMemory(builder, totalMemory);
  io.testable.agent.AgentStatus.addCpu(builder, cpu);
  io.testable.agent.AgentStatus.addTotalCpus(builder, totalCpus);
  io.testable.agent.AgentStatus.addActiveChunks(builder, activeChunksOffset);
  io.testable.agent.AgentStatus.addInstanceId(builder, instanceIdOffset);
  io.testable.agent.AgentStatus.addProductCode(builder, productCodeOffset);
  io.testable.agent.AgentStatus.addAgentVersion(builder, agentVersionOffset);
  io.testable.agent.AgentStatus.addPlatform(builder, platformOffset);
  io.testable.agent.AgentStatus.addInstalledBrowsers(builder, installedBrowsersOffset);
  io.testable.agent.AgentStatus.addRequiresLock(builder, requiresLock);
  io.testable.agent.AgentStatus.addKeys(builder, keysOffset);
  io.testable.agent.AgentStatus.addRegions(builder, regionsOffset);
  io.testable.agent.AgentStatus.addScenarioTypes(builder, scenarioTypesOffset);
  return io.testable.agent.AgentStatus.endAgentStatus(builder);
}

/**
 * @constructor
 */
io.testable.agent.LocationInfo = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.LocationInfo}
 */
io.testable.agent.LocationInfo.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.LocationInfo=} obj
 * @returns {io.testable.agent.LocationInfo}
 */
io.testable.agent.LocationInfo.getRootAsLocationInfo = function(bb, obj) {
  return (obj || new io.testable.agent.LocationInfo).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.LocationInfo=} obj
 * @returns {io.testable.agent.LocationInfo}
 */
io.testable.agent.LocationInfo.getSizePrefixedRootAsLocationInfo = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.LocationInfo).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.LocationInfo.prototype.ip = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {number}
 */
io.testable.agent.LocationInfo.prototype.latitude = function() {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.readFloat64(this.bb_pos + offset) : 0.0;
};

/**
 * @returns {number}
 */
io.testable.agent.LocationInfo.prototype.longitude = function() {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.readFloat64(this.bb_pos + offset) : 0.0;
};

/**
 * @returns {boolean}
 */
io.testable.agent.LocationInfo.prototype.hasLatLong = function() {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? !!this.bb.readInt8(this.bb_pos + offset) : false;
};

/**
 * @returns {boolean}
 */
io.testable.agent.LocationInfo.prototype.updateLatLong = function() {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? !!this.bb.readInt8(this.bb_pos + offset) : true;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.LocationInfo.startLocationInfo = function(builder) {
  builder.startObject(5);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} ipOffset
 */
io.testable.agent.LocationInfo.addIp = function(builder, ipOffset) {
  builder.addFieldOffset(0, ipOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} latitude
 */
io.testable.agent.LocationInfo.addLatitude = function(builder, latitude) {
  builder.addFieldFloat64(1, latitude, 0.0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} longitude
 */
io.testable.agent.LocationInfo.addLongitude = function(builder, longitude) {
  builder.addFieldFloat64(2, longitude, 0.0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {boolean} hasLatLong
 */
io.testable.agent.LocationInfo.addHasLatLong = function(builder, hasLatLong) {
  builder.addFieldInt8(3, +hasLatLong, +false);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {boolean} updateLatLong
 */
io.testable.agent.LocationInfo.addUpdateLatLong = function(builder, updateLatLong) {
  builder.addFieldInt8(4, +updateLatLong, +true);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.LocationInfo.endLocationInfo = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} ipOffset
 * @param {number} latitude
 * @param {number} longitude
 * @param {boolean} hasLatLong
 * @param {boolean} updateLatLong
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.LocationInfo.createLocationInfo = function(builder, ipOffset, latitude, longitude, hasLatLong, updateLatLong) {
  io.testable.agent.LocationInfo.startLocationInfo(builder);
  io.testable.agent.LocationInfo.addIp(builder, ipOffset);
  io.testable.agent.LocationInfo.addLatitude(builder, latitude);
  io.testable.agent.LocationInfo.addLongitude(builder, longitude);
  io.testable.agent.LocationInfo.addHasLatLong(builder, hasLatLong);
  io.testable.agent.LocationInfo.addUpdateLatLong(builder, updateLatLong);
  return io.testable.agent.LocationInfo.endLocationInfo(builder);
}

/**
 * @constructor
 */
io.testable.agent.ChunkInfo = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.ChunkInfo}
 */
io.testable.agent.ChunkInfo.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.ChunkInfo=} obj
 * @returns {io.testable.agent.ChunkInfo}
 */
io.testable.agent.ChunkInfo.getRootAsChunkInfo = function(bb, obj) {
  return (obj || new io.testable.agent.ChunkInfo).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.ChunkInfo=} obj
 * @returns {io.testable.agent.ChunkInfo}
 */
io.testable.agent.ChunkInfo.getSizePrefixedRootAsChunkInfo = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.ChunkInfo).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.ChunkInfo.prototype.id = function() {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.ChunkInfo.prototype.execution = function() {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.ChunkInfo.prototype.executionRegion = function() {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.ChunkInfo.prototype.concurrentClients = function() {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.ChunkInfo.prototype.concurrentClientId = function() {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {io.testable.agent.ChunkOutcome}
 */
io.testable.agent.ChunkInfo.prototype.outcome = function() {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? /** @type {io.testable.agent.ChunkOutcome} */ (this.bb.readInt8(this.bb_pos + offset)) : io.testable.agent.ChunkOutcome.None;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.ChunkInfo.startChunkInfo = function(builder) {
  builder.startObject(6);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} id
 */
io.testable.agent.ChunkInfo.addId = function(builder, id) {
  builder.addFieldInt64(0, id, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} execution
 */
io.testable.agent.ChunkInfo.addExecution = function(builder, execution) {
  builder.addFieldInt64(1, execution, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} executionRegion
 */
io.testable.agent.ChunkInfo.addExecutionRegion = function(builder, executionRegion) {
  builder.addFieldInt64(2, executionRegion, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} concurrentClients
 */
io.testable.agent.ChunkInfo.addConcurrentClients = function(builder, concurrentClients) {
  builder.addFieldInt64(3, concurrentClients, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} concurrentClientId
 */
io.testable.agent.ChunkInfo.addConcurrentClientId = function(builder, concurrentClientId) {
  builder.addFieldInt64(4, concurrentClientId, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {io.testable.agent.ChunkOutcome} outcome
 */
io.testable.agent.ChunkInfo.addOutcome = function(builder, outcome) {
  builder.addFieldInt8(5, outcome, io.testable.agent.ChunkOutcome.None);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.ChunkInfo.endChunkInfo = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} id
 * @param {flatbuffers.Long} execution
 * @param {flatbuffers.Long} executionRegion
 * @param {flatbuffers.Long} concurrentClients
 * @param {flatbuffers.Long} concurrentClientId
 * @param {io.testable.agent.ChunkOutcome} outcome
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.ChunkInfo.createChunkInfo = function(builder, id, execution, executionRegion, concurrentClients, concurrentClientId, outcome) {
  io.testable.agent.ChunkInfo.startChunkInfo(builder);
  io.testable.agent.ChunkInfo.addId(builder, id);
  io.testable.agent.ChunkInfo.addExecution(builder, execution);
  io.testable.agent.ChunkInfo.addExecutionRegion(builder, executionRegion);
  io.testable.agent.ChunkInfo.addConcurrentClients(builder, concurrentClients);
  io.testable.agent.ChunkInfo.addConcurrentClientId(builder, concurrentClientId);
  io.testable.agent.ChunkInfo.addOutcome(builder, outcome);
  return io.testable.agent.ChunkInfo.endChunkInfo(builder);
}

/**
 * @constructor
 */
io.testable.agent.RegionInfo = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.RegionInfo}
 */
io.testable.agent.RegionInfo.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.RegionInfo=} obj
 * @returns {io.testable.agent.RegionInfo}
 */
io.testable.agent.RegionInfo.getRootAsRegionInfo = function(bb, obj) {
  return (obj || new io.testable.agent.RegionInfo).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.RegionInfo=} obj
 * @returns {io.testable.agent.RegionInfo}
 */
io.testable.agent.RegionInfo.getSizePrefixedRootAsRegionInfo = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.RegionInfo).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.RegionInfo.prototype.name = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.RegionInfo.prototype.scope = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.RegionInfo.prototype.description = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.RegionInfo.prototype.provider = function() {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(-1, -1);
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.RegionInfo.startRegionInfo = function(builder) {
  builder.startObject(4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} nameOffset
 */
io.testable.agent.RegionInfo.addName = function(builder, nameOffset) {
  builder.addFieldOffset(0, nameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} scopeOffset
 */
io.testable.agent.RegionInfo.addScope = function(builder, scopeOffset) {
  builder.addFieldOffset(1, scopeOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} descriptionOffset
 */
io.testable.agent.RegionInfo.addDescription = function(builder, descriptionOffset) {
  builder.addFieldOffset(2, descriptionOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} provider
 */
io.testable.agent.RegionInfo.addProvider = function(builder, provider) {
  builder.addFieldInt64(3, provider, builder.createLong(-1, -1));
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.RegionInfo.endRegionInfo = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} nameOffset
 * @param {flatbuffers.Offset} scopeOffset
 * @param {flatbuffers.Offset} descriptionOffset
 * @param {flatbuffers.Long} provider
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.RegionInfo.createRegionInfo = function(builder, nameOffset, scopeOffset, descriptionOffset, provider) {
  io.testable.agent.RegionInfo.startRegionInfo(builder);
  io.testable.agent.RegionInfo.addName(builder, nameOffset);
  io.testable.agent.RegionInfo.addScope(builder, scopeOffset);
  io.testable.agent.RegionInfo.addDescription(builder, descriptionOffset);
  io.testable.agent.RegionInfo.addProvider(builder, provider);
  return io.testable.agent.RegionInfo.endRegionInfo(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentHealth = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentHealth}
 */
io.testable.agent.AgentHealth.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentHealth=} obj
 * @returns {io.testable.agent.AgentHealth}
 */
io.testable.agent.AgentHealth.getRootAsAgentHealth = function(bb, obj) {
  return (obj || new io.testable.agent.AgentHealth).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentHealth=} obj
 * @returns {io.testable.agent.AgentHealth}
 */
io.testable.agent.AgentHealth.getSizePrefixedRootAsAgentHealth = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentHealth).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentHealth.prototype.agentId = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentHealth.prototype.regionName = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentHealth.prototype.timestamp = function() {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @param {number} index
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentHealth.prototype.activeChunks = function(index) {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.readInt64(this.bb.__vector(this.bb_pos + offset) + index * 8) : this.bb.createLong(0, 0);
};

/**
 * @returns {number}
 */
io.testable.agent.AgentHealth.prototype.activeChunksLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentHealth.prototype.activeConcurrentClients = function() {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {number}
 */
io.testable.agent.AgentHealth.prototype.cpu = function() {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? this.bb.readFloat64(this.bb_pos + offset) : 0.0;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentHealth.prototype.totalMemory = function() {
  var offset = this.bb.__offset(this.bb_pos, 16);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentHealth.prototype.freeMemory = function() {
  var offset = this.bb.__offset(this.bb_pos, 18);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentHealth.prototype.usedMemory = function() {
  var offset = this.bb.__offset(this.bb_pos, 20);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentHealth.prototype.networkSent = function() {
  var offset = this.bb.__offset(this.bb_pos, 22);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentHealth.prototype.networkReceived = function() {
  var offset = this.bb.__offset(this.bb_pos, 24);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentHealth.prototype.connections = function() {
  var offset = this.bb.__offset(this.bb_pos, 26);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentHealth.prototype.cpuUtilization = function() {
  var offset = this.bb.__offset(this.bb_pos, 28);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @param {number} index
 * @param {io.testable.agent.Browser=} obj
 * @returns {io.testable.agent.Browser}
 */
io.testable.agent.AgentHealth.prototype.installedBrowsers = function(index, obj) {
  var offset = this.bb.__offset(this.bb_pos, 30);
  return offset ? (obj || new io.testable.agent.Browser).__init(this.bb.__indirect(this.bb.__vector(this.bb_pos + offset) + index * 4), this.bb) : null;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentHealth.prototype.installedBrowsersLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 30);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentHealth.startAgentHealth = function(builder) {
  builder.startObject(14);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} agentIdOffset
 */
io.testable.agent.AgentHealth.addAgentId = function(builder, agentIdOffset) {
  builder.addFieldOffset(0, agentIdOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} regionNameOffset
 */
io.testable.agent.AgentHealth.addRegionName = function(builder, regionNameOffset) {
  builder.addFieldOffset(1, regionNameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} timestamp
 */
io.testable.agent.AgentHealth.addTimestamp = function(builder, timestamp) {
  builder.addFieldInt64(2, timestamp, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} activeChunksOffset
 */
io.testable.agent.AgentHealth.addActiveChunks = function(builder, activeChunksOffset) {
  builder.addFieldOffset(3, activeChunksOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Long>} data
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentHealth.createActiveChunksVector = function(builder, data) {
  builder.startVector(8, data.length, 8);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addInt64(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
io.testable.agent.AgentHealth.startActiveChunksVector = function(builder, numElems) {
  builder.startVector(8, numElems, 8);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} activeConcurrentClients
 */
io.testable.agent.AgentHealth.addActiveConcurrentClients = function(builder, activeConcurrentClients) {
  builder.addFieldInt64(4, activeConcurrentClients, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} cpu
 */
io.testable.agent.AgentHealth.addCpu = function(builder, cpu) {
  builder.addFieldFloat64(5, cpu, 0.0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} totalMemory
 */
io.testable.agent.AgentHealth.addTotalMemory = function(builder, totalMemory) {
  builder.addFieldInt64(6, totalMemory, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} freeMemory
 */
io.testable.agent.AgentHealth.addFreeMemory = function(builder, freeMemory) {
  builder.addFieldInt64(7, freeMemory, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} usedMemory
 */
io.testable.agent.AgentHealth.addUsedMemory = function(builder, usedMemory) {
  builder.addFieldInt64(8, usedMemory, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} networkSent
 */
io.testable.agent.AgentHealth.addNetworkSent = function(builder, networkSent) {
  builder.addFieldInt64(9, networkSent, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} networkReceived
 */
io.testable.agent.AgentHealth.addNetworkReceived = function(builder, networkReceived) {
  builder.addFieldInt64(10, networkReceived, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} connections
 */
io.testable.agent.AgentHealth.addConnections = function(builder, connections) {
  builder.addFieldInt64(11, connections, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} cpuUtilization
 */
io.testable.agent.AgentHealth.addCpuUtilization = function(builder, cpuUtilization) {
  builder.addFieldInt64(12, cpuUtilization, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} installedBrowsersOffset
 */
io.testable.agent.AgentHealth.addInstalledBrowsers = function(builder, installedBrowsersOffset) {
  builder.addFieldOffset(13, installedBrowsersOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentHealth.createInstalledBrowsersVector = function(builder, data) {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
io.testable.agent.AgentHealth.startInstalledBrowsersVector = function(builder, numElems) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentHealth.endAgentHealth = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} agentIdOffset
 * @param {flatbuffers.Offset} regionNameOffset
 * @param {flatbuffers.Long} timestamp
 * @param {flatbuffers.Offset} activeChunksOffset
 * @param {flatbuffers.Long} activeConcurrentClients
 * @param {number} cpu
 * @param {flatbuffers.Long} totalMemory
 * @param {flatbuffers.Long} freeMemory
 * @param {flatbuffers.Long} usedMemory
 * @param {flatbuffers.Long} networkSent
 * @param {flatbuffers.Long} networkReceived
 * @param {flatbuffers.Long} connections
 * @param {flatbuffers.Long} cpuUtilization
 * @param {flatbuffers.Offset} installedBrowsersOffset
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentHealth.createAgentHealth = function(builder, agentIdOffset, regionNameOffset, timestamp, activeChunksOffset, activeConcurrentClients, cpu, totalMemory, freeMemory, usedMemory, networkSent, networkReceived, connections, cpuUtilization, installedBrowsersOffset) {
  io.testable.agent.AgentHealth.startAgentHealth(builder);
  io.testable.agent.AgentHealth.addAgentId(builder, agentIdOffset);
  io.testable.agent.AgentHealth.addRegionName(builder, regionNameOffset);
  io.testable.agent.AgentHealth.addTimestamp(builder, timestamp);
  io.testable.agent.AgentHealth.addActiveChunks(builder, activeChunksOffset);
  io.testable.agent.AgentHealth.addActiveConcurrentClients(builder, activeConcurrentClients);
  io.testable.agent.AgentHealth.addCpu(builder, cpu);
  io.testable.agent.AgentHealth.addTotalMemory(builder, totalMemory);
  io.testable.agent.AgentHealth.addFreeMemory(builder, freeMemory);
  io.testable.agent.AgentHealth.addUsedMemory(builder, usedMemory);
  io.testable.agent.AgentHealth.addNetworkSent(builder, networkSent);
  io.testable.agent.AgentHealth.addNetworkReceived(builder, networkReceived);
  io.testable.agent.AgentHealth.addConnections(builder, connections);
  io.testable.agent.AgentHealth.addCpuUtilization(builder, cpuUtilization);
  io.testable.agent.AgentHealth.addInstalledBrowsers(builder, installedBrowsersOffset);
  return io.testable.agent.AgentHealth.endAgentHealth(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentConcurrentClient = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentConcurrentClient}
 */
io.testable.agent.AgentConcurrentClient.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentConcurrentClient=} obj
 * @returns {io.testable.agent.AgentConcurrentClient}
 */
io.testable.agent.AgentConcurrentClient.getRootAsAgentConcurrentClient = function(bb, obj) {
  return (obj || new io.testable.agent.AgentConcurrentClient).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentConcurrentClient=} obj
 * @returns {io.testable.agent.AgentConcurrentClient}
 */
io.testable.agent.AgentConcurrentClient.getSizePrefixedRootAsAgentConcurrentClient = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentConcurrentClient).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentConcurrentClient.prototype.testCase = function() {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentConcurrentClient.prototype.execution = function() {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentConcurrentClient.prototype.executionRegion = function() {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentConcurrentClient.prototype.chunk = function() {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentConcurrentClient.prototype.threadId = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentConcurrentClient.prototype.count = function() {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentConcurrentClient.prototype.actualCount = function() {
  var offset = this.bb.__offset(this.bb_pos, 16);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentConcurrentClient.prototype.agentId = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 18);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentConcurrentClient.prototype.regionName = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 20);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentConcurrentClient.prototype.timestamp = function() {
  var offset = this.bb.__offset(this.bb_pos, 22);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentConcurrentClient.prototype.org = function() {
  var offset = this.bb.__offset(this.bb_pos, 24);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {io.testable.agent.TestType}
 */
io.testable.agent.AgentConcurrentClient.prototype.testType = function() {
  var offset = this.bb.__offset(this.bb_pos, 26);
  return offset ? /** @type {io.testable.agent.TestType} */ (this.bb.readInt8(this.bb_pos + offset)) : io.testable.agent.TestType.Load;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentConcurrentClient.startAgentConcurrentClient = function(builder) {
  builder.startObject(12);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} testCase
 */
io.testable.agent.AgentConcurrentClient.addTestCase = function(builder, testCase) {
  builder.addFieldInt64(0, testCase, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} execution
 */
io.testable.agent.AgentConcurrentClient.addExecution = function(builder, execution) {
  builder.addFieldInt64(1, execution, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} executionRegion
 */
io.testable.agent.AgentConcurrentClient.addExecutionRegion = function(builder, executionRegion) {
  builder.addFieldInt64(2, executionRegion, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} chunk
 */
io.testable.agent.AgentConcurrentClient.addChunk = function(builder, chunk) {
  builder.addFieldInt64(3, chunk, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} threadIdOffset
 */
io.testable.agent.AgentConcurrentClient.addThreadId = function(builder, threadIdOffset) {
  builder.addFieldOffset(4, threadIdOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} count
 */
io.testable.agent.AgentConcurrentClient.addCount = function(builder, count) {
  builder.addFieldInt64(5, count, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} actualCount
 */
io.testable.agent.AgentConcurrentClient.addActualCount = function(builder, actualCount) {
  builder.addFieldInt64(6, actualCount, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} agentIdOffset
 */
io.testable.agent.AgentConcurrentClient.addAgentId = function(builder, agentIdOffset) {
  builder.addFieldOffset(7, agentIdOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} regionNameOffset
 */
io.testable.agent.AgentConcurrentClient.addRegionName = function(builder, regionNameOffset) {
  builder.addFieldOffset(8, regionNameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} timestamp
 */
io.testable.agent.AgentConcurrentClient.addTimestamp = function(builder, timestamp) {
  builder.addFieldInt64(9, timestamp, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} org
 */
io.testable.agent.AgentConcurrentClient.addOrg = function(builder, org) {
  builder.addFieldInt64(10, org, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {io.testable.agent.TestType} testType
 */
io.testable.agent.AgentConcurrentClient.addTestType = function(builder, testType) {
  builder.addFieldInt8(11, testType, io.testable.agent.TestType.Load);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentConcurrentClient.endAgentConcurrentClient = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} testCase
 * @param {flatbuffers.Long} execution
 * @param {flatbuffers.Long} executionRegion
 * @param {flatbuffers.Long} chunk
 * @param {flatbuffers.Offset} threadIdOffset
 * @param {flatbuffers.Long} count
 * @param {flatbuffers.Long} actualCount
 * @param {flatbuffers.Offset} agentIdOffset
 * @param {flatbuffers.Offset} regionNameOffset
 * @param {flatbuffers.Long} timestamp
 * @param {flatbuffers.Long} org
 * @param {io.testable.agent.TestType} testType
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentConcurrentClient.createAgentConcurrentClient = function(builder, testCase, execution, executionRegion, chunk, threadIdOffset, count, actualCount, agentIdOffset, regionNameOffset, timestamp, org, testType) {
  io.testable.agent.AgentConcurrentClient.startAgentConcurrentClient(builder);
  io.testable.agent.AgentConcurrentClient.addTestCase(builder, testCase);
  io.testable.agent.AgentConcurrentClient.addExecution(builder, execution);
  io.testable.agent.AgentConcurrentClient.addExecutionRegion(builder, executionRegion);
  io.testable.agent.AgentConcurrentClient.addChunk(builder, chunk);
  io.testable.agent.AgentConcurrentClient.addThreadId(builder, threadIdOffset);
  io.testable.agent.AgentConcurrentClient.addCount(builder, count);
  io.testable.agent.AgentConcurrentClient.addActualCount(builder, actualCount);
  io.testable.agent.AgentConcurrentClient.addAgentId(builder, agentIdOffset);
  io.testable.agent.AgentConcurrentClient.addRegionName(builder, regionNameOffset);
  io.testable.agent.AgentConcurrentClient.addTimestamp(builder, timestamp);
  io.testable.agent.AgentConcurrentClient.addOrg(builder, org);
  io.testable.agent.AgentConcurrentClient.addTestType(builder, testType);
  return io.testable.agent.AgentConcurrentClient.endAgentConcurrentClient(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentChunkProgress = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentChunkProgress}
 */
io.testable.agent.AgentChunkProgress.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentChunkProgress=} obj
 * @returns {io.testable.agent.AgentChunkProgress}
 */
io.testable.agent.AgentChunkProgress.getRootAsAgentChunkProgress = function(bb, obj) {
  return (obj || new io.testable.agent.AgentChunkProgress).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentChunkProgress=} obj
 * @returns {io.testable.agent.AgentChunkProgress}
 */
io.testable.agent.AgentChunkProgress.getSizePrefixedRootAsAgentChunkProgress = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentChunkProgress).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkProgress.prototype.timestamp = function() {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkProgress.prototype.execution = function() {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkProgress.prototype.executionRegion = function() {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkProgress.prototype.chunk = function() {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkProgress.prototype.completedDurationSecs = function() {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkProgress.prototype.completedIterations = function() {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {number}
 */
io.testable.agent.AgentChunkProgress.prototype.cpu = function() {
  var offset = this.bb.__offset(this.bb_pos, 16);
  return offset ? this.bb.readFloat64(this.bb_pos + offset) : 0.0;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkProgress.prototype.memory = function() {
  var offset = this.bb.__offset(this.bb_pos, 18);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkProgress.prototype.cpuUtilization = function() {
  var offset = this.bb.__offset(this.bb_pos, 20);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkProgress.prototype.memoryUtilization = function() {
  var offset = this.bb.__offset(this.bb_pos, 22);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentChunkProgress.startAgentChunkProgress = function(builder) {
  builder.startObject(10);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} timestamp
 */
io.testable.agent.AgentChunkProgress.addTimestamp = function(builder, timestamp) {
  builder.addFieldInt64(0, timestamp, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} execution
 */
io.testable.agent.AgentChunkProgress.addExecution = function(builder, execution) {
  builder.addFieldInt64(1, execution, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} executionRegion
 */
io.testable.agent.AgentChunkProgress.addExecutionRegion = function(builder, executionRegion) {
  builder.addFieldInt64(2, executionRegion, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} chunk
 */
io.testable.agent.AgentChunkProgress.addChunk = function(builder, chunk) {
  builder.addFieldInt64(3, chunk, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} completedDurationSecs
 */
io.testable.agent.AgentChunkProgress.addCompletedDurationSecs = function(builder, completedDurationSecs) {
  builder.addFieldInt64(4, completedDurationSecs, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} completedIterations
 */
io.testable.agent.AgentChunkProgress.addCompletedIterations = function(builder, completedIterations) {
  builder.addFieldInt64(5, completedIterations, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} cpu
 */
io.testable.agent.AgentChunkProgress.addCpu = function(builder, cpu) {
  builder.addFieldFloat64(6, cpu, 0.0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} memory
 */
io.testable.agent.AgentChunkProgress.addMemory = function(builder, memory) {
  builder.addFieldInt64(7, memory, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} cpuUtilization
 */
io.testable.agent.AgentChunkProgress.addCpuUtilization = function(builder, cpuUtilization) {
  builder.addFieldInt64(8, cpuUtilization, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} memoryUtilization
 */
io.testable.agent.AgentChunkProgress.addMemoryUtilization = function(builder, memoryUtilization) {
  builder.addFieldInt64(9, memoryUtilization, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentChunkProgress.endAgentChunkProgress = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} timestamp
 * @param {flatbuffers.Long} execution
 * @param {flatbuffers.Long} executionRegion
 * @param {flatbuffers.Long} chunk
 * @param {flatbuffers.Long} completedDurationSecs
 * @param {flatbuffers.Long} completedIterations
 * @param {number} cpu
 * @param {flatbuffers.Long} memory
 * @param {flatbuffers.Long} cpuUtilization
 * @param {flatbuffers.Long} memoryUtilization
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentChunkProgress.createAgentChunkProgress = function(builder, timestamp, execution, executionRegion, chunk, completedDurationSecs, completedIterations, cpu, memory, cpuUtilization, memoryUtilization) {
  io.testable.agent.AgentChunkProgress.startAgentChunkProgress(builder);
  io.testable.agent.AgentChunkProgress.addTimestamp(builder, timestamp);
  io.testable.agent.AgentChunkProgress.addExecution(builder, execution);
  io.testable.agent.AgentChunkProgress.addExecutionRegion(builder, executionRegion);
  io.testable.agent.AgentChunkProgress.addChunk(builder, chunk);
  io.testable.agent.AgentChunkProgress.addCompletedDurationSecs(builder, completedDurationSecs);
  io.testable.agent.AgentChunkProgress.addCompletedIterations(builder, completedIterations);
  io.testable.agent.AgentChunkProgress.addCpu(builder, cpu);
  io.testable.agent.AgentChunkProgress.addMemory(builder, memory);
  io.testable.agent.AgentChunkProgress.addCpuUtilization(builder, cpuUtilization);
  io.testable.agent.AgentChunkProgress.addMemoryUtilization(builder, memoryUtilization);
  return io.testable.agent.AgentChunkProgress.endAgentChunkProgress(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentChunkResults = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentChunkResults}
 */
io.testable.agent.AgentChunkResults.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentChunkResults=} obj
 * @returns {io.testable.agent.AgentChunkResults}
 */
io.testable.agent.AgentChunkResults.getRootAsAgentChunkResults = function(bb, obj) {
  return (obj || new io.testable.agent.AgentChunkResults).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentChunkResults=} obj
 * @returns {io.testable.agent.AgentChunkResults}
 */
io.testable.agent.AgentChunkResults.getSizePrefixedRootAsAgentChunkResults = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentChunkResults).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkResults.prototype.testCase = function() {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkResults.prototype.execution = function() {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkResults.prototype.executionRegion = function() {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkResults.prototype.chunk = function() {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {io.testable.agent.ChunkExecutionType}
 */
io.testable.agent.AgentChunkResults.prototype.executionType = function() {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? /** @type {io.testable.agent.ChunkExecutionType} */ (this.bb.readInt8(this.bb_pos + offset)) : io.testable.agent.ChunkExecutionType.Init;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentChunkResults.prototype.agentId = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentChunkResults.prototype.regionName = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 16);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {number} index
 * @param {io.testable.agent.AgentChunkResult=} obj
 * @returns {io.testable.agent.AgentChunkResult}
 */
io.testable.agent.AgentChunkResults.prototype.results = function(index, obj) {
  var offset = this.bb.__offset(this.bb_pos, 18);
  return offset ? (obj || new io.testable.agent.AgentChunkResult).__init(this.bb.__indirect(this.bb.__vector(this.bb_pos + offset) + index * 4), this.bb) : null;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentChunkResults.prototype.resultsLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 18);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkResults.prototype.org = function() {
  var offset = this.bb.__offset(this.bb_pos, 20);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @param {number} index
 * @param {io.testable.agent.AgentFile=} obj
 * @returns {io.testable.agent.AgentFile}
 */
io.testable.agent.AgentChunkResults.prototype.files = function(index, obj) {
  var offset = this.bb.__offset(this.bb_pos, 22);
  return offset ? (obj || new io.testable.agent.AgentFile).__init(this.bb.__indirect(this.bb.__vector(this.bb_pos + offset) + index * 4), this.bb) : null;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentChunkResults.prototype.filesLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 22);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @returns {boolean}
 */
io.testable.agent.AgentChunkResults.prototype.isReplay = function() {
  var offset = this.bb.__offset(this.bb_pos, 24);
  return offset ? !!this.bb.readInt8(this.bb_pos + offset) : false;
};

/**
 * @returns {io.testable.agent.TestType}
 */
io.testable.agent.AgentChunkResults.prototype.testType = function() {
  var offset = this.bb.__offset(this.bb_pos, 26);
  return offset ? /** @type {io.testable.agent.TestType} */ (this.bb.readInt8(this.bb_pos + offset)) : io.testable.agent.TestType.Load;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkResults.prototype.regionId = function() {
  var offset = this.bb.__offset(this.bb_pos, 28);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {number}
 */
io.testable.agent.AgentChunkResults.prototype.chunkIndex = function() {
  var offset = this.bb.__offset(this.bb_pos, 30);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @param {number} index
 * @param {io.testable.agent.AgentSuiteResult=} obj
 * @returns {io.testable.agent.AgentSuiteResult}
 */
io.testable.agent.AgentChunkResults.prototype.testSuiteResults = function(index, obj) {
  var offset = this.bb.__offset(this.bb_pos, 32);
  return offset ? (obj || new io.testable.agent.AgentSuiteResult).__init(this.bb.__indirect(this.bb.__vector(this.bb_pos + offset) + index * 4), this.bb) : null;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentChunkResults.prototype.testSuiteResultsLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 32);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @param {io.testable.agent.AgentChunkTrace=} obj
 * @returns {io.testable.agent.AgentChunkTrace|null}
 */
io.testable.agent.AgentChunkResults.prototype.traceContinuation = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 34);
  return offset ? (obj || new io.testable.agent.AgentChunkTrace).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {number} index
 * @param {io.testable.agent.AgentSuiteAction=} obj
 * @returns {io.testable.agent.AgentSuiteAction}
 */
io.testable.agent.AgentChunkResults.prototype.suiteActions = function(index, obj) {
  var offset = this.bb.__offset(this.bb_pos, 36);
  return offset ? (obj || new io.testable.agent.AgentSuiteAction).__init(this.bb.__indirect(this.bb.__vector(this.bb_pos + offset) + index * 4), this.bb) : null;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentChunkResults.prototype.suiteActionsLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 36);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @param {number} index
 * @param {io.testable.agent.AgentCommandAction=} obj
 * @returns {io.testable.agent.AgentCommandAction}
 */
io.testable.agent.AgentChunkResults.prototype.commands = function(index, obj) {
  var offset = this.bb.__offset(this.bb_pos, 38);
  return offset ? (obj || new io.testable.agent.AgentCommandAction).__init(this.bb.__indirect(this.bb.__vector(this.bb_pos + offset) + index * 4), this.bb) : null;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentChunkResults.prototype.commandsLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 38);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentChunkResults.startAgentChunkResults = function(builder) {
  builder.startObject(18);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} testCase
 */
io.testable.agent.AgentChunkResults.addTestCase = function(builder, testCase) {
  builder.addFieldInt64(0, testCase, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} execution
 */
io.testable.agent.AgentChunkResults.addExecution = function(builder, execution) {
  builder.addFieldInt64(1, execution, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} executionRegion
 */
io.testable.agent.AgentChunkResults.addExecutionRegion = function(builder, executionRegion) {
  builder.addFieldInt64(2, executionRegion, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} chunk
 */
io.testable.agent.AgentChunkResults.addChunk = function(builder, chunk) {
  builder.addFieldInt64(3, chunk, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {io.testable.agent.ChunkExecutionType} executionType
 */
io.testable.agent.AgentChunkResults.addExecutionType = function(builder, executionType) {
  builder.addFieldInt8(4, executionType, io.testable.agent.ChunkExecutionType.Init);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} agentIdOffset
 */
io.testable.agent.AgentChunkResults.addAgentId = function(builder, agentIdOffset) {
  builder.addFieldOffset(5, agentIdOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} regionNameOffset
 */
io.testable.agent.AgentChunkResults.addRegionName = function(builder, regionNameOffset) {
  builder.addFieldOffset(6, regionNameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} resultsOffset
 */
io.testable.agent.AgentChunkResults.addResults = function(builder, resultsOffset) {
  builder.addFieldOffset(7, resultsOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentChunkResults.createResultsVector = function(builder, data) {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
io.testable.agent.AgentChunkResults.startResultsVector = function(builder, numElems) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} org
 */
io.testable.agent.AgentChunkResults.addOrg = function(builder, org) {
  builder.addFieldInt64(8, org, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} filesOffset
 */
io.testable.agent.AgentChunkResults.addFiles = function(builder, filesOffset) {
  builder.addFieldOffset(9, filesOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentChunkResults.createFilesVector = function(builder, data) {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
io.testable.agent.AgentChunkResults.startFilesVector = function(builder, numElems) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {boolean} isReplay
 */
io.testable.agent.AgentChunkResults.addIsReplay = function(builder, isReplay) {
  builder.addFieldInt8(10, +isReplay, +false);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {io.testable.agent.TestType} testType
 */
io.testable.agent.AgentChunkResults.addTestType = function(builder, testType) {
  builder.addFieldInt8(11, testType, io.testable.agent.TestType.Load);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} regionId
 */
io.testable.agent.AgentChunkResults.addRegionId = function(builder, regionId) {
  builder.addFieldInt64(12, regionId, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} chunkIndex
 */
io.testable.agent.AgentChunkResults.addChunkIndex = function(builder, chunkIndex) {
  builder.addFieldInt32(13, chunkIndex, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} testSuiteResultsOffset
 */
io.testable.agent.AgentChunkResults.addTestSuiteResults = function(builder, testSuiteResultsOffset) {
  builder.addFieldOffset(14, testSuiteResultsOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentChunkResults.createTestSuiteResultsVector = function(builder, data) {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
io.testable.agent.AgentChunkResults.startTestSuiteResultsVector = function(builder, numElems) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} traceContinuationOffset
 */
io.testable.agent.AgentChunkResults.addTraceContinuation = function(builder, traceContinuationOffset) {
  builder.addFieldOffset(15, traceContinuationOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} suiteActionsOffset
 */
io.testable.agent.AgentChunkResults.addSuiteActions = function(builder, suiteActionsOffset) {
  builder.addFieldOffset(16, suiteActionsOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentChunkResults.createSuiteActionsVector = function(builder, data) {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
io.testable.agent.AgentChunkResults.startSuiteActionsVector = function(builder, numElems) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} commandsOffset
 */
io.testable.agent.AgentChunkResults.addCommands = function(builder, commandsOffset) {
  builder.addFieldOffset(17, commandsOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentChunkResults.createCommandsVector = function(builder, data) {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
io.testable.agent.AgentChunkResults.startCommandsVector = function(builder, numElems) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentChunkResults.endAgentChunkResults = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} testCase
 * @param {flatbuffers.Long} execution
 * @param {flatbuffers.Long} executionRegion
 * @param {flatbuffers.Long} chunk
 * @param {io.testable.agent.ChunkExecutionType} executionType
 * @param {flatbuffers.Offset} agentIdOffset
 * @param {flatbuffers.Offset} regionNameOffset
 * @param {flatbuffers.Offset} resultsOffset
 * @param {flatbuffers.Long} org
 * @param {flatbuffers.Offset} filesOffset
 * @param {boolean} isReplay
 * @param {io.testable.agent.TestType} testType
 * @param {flatbuffers.Long} regionId
 * @param {number} chunkIndex
 * @param {flatbuffers.Offset} testSuiteResultsOffset
 * @param {flatbuffers.Offset} traceContinuationOffset
 * @param {flatbuffers.Offset} suiteActionsOffset
 * @param {flatbuffers.Offset} commandsOffset
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentChunkResults.createAgentChunkResults = function(builder, testCase, execution, executionRegion, chunk, executionType, agentIdOffset, regionNameOffset, resultsOffset, org, filesOffset, isReplay, testType, regionId, chunkIndex, testSuiteResultsOffset, traceContinuationOffset, suiteActionsOffset, commandsOffset) {
  io.testable.agent.AgentChunkResults.startAgentChunkResults(builder);
  io.testable.agent.AgentChunkResults.addTestCase(builder, testCase);
  io.testable.agent.AgentChunkResults.addExecution(builder, execution);
  io.testable.agent.AgentChunkResults.addExecutionRegion(builder, executionRegion);
  io.testable.agent.AgentChunkResults.addChunk(builder, chunk);
  io.testable.agent.AgentChunkResults.addExecutionType(builder, executionType);
  io.testable.agent.AgentChunkResults.addAgentId(builder, agentIdOffset);
  io.testable.agent.AgentChunkResults.addRegionName(builder, regionNameOffset);
  io.testable.agent.AgentChunkResults.addResults(builder, resultsOffset);
  io.testable.agent.AgentChunkResults.addOrg(builder, org);
  io.testable.agent.AgentChunkResults.addFiles(builder, filesOffset);
  io.testable.agent.AgentChunkResults.addIsReplay(builder, isReplay);
  io.testable.agent.AgentChunkResults.addTestType(builder, testType);
  io.testable.agent.AgentChunkResults.addRegionId(builder, regionId);
  io.testable.agent.AgentChunkResults.addChunkIndex(builder, chunkIndex);
  io.testable.agent.AgentChunkResults.addTestSuiteResults(builder, testSuiteResultsOffset);
  io.testable.agent.AgentChunkResults.addTraceContinuation(builder, traceContinuationOffset);
  io.testable.agent.AgentChunkResults.addSuiteActions(builder, suiteActionsOffset);
  io.testable.agent.AgentChunkResults.addCommands(builder, commandsOffset);
  return io.testable.agent.AgentChunkResults.endAgentChunkResults(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentChunkResult = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentChunkResult}
 */
io.testable.agent.AgentChunkResult.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentChunkResult=} obj
 * @returns {io.testable.agent.AgentChunkResult}
 */
io.testable.agent.AgentChunkResult.getRootAsAgentChunkResult = function(bb, obj) {
  return (obj || new io.testable.agent.AgentChunkResult).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentChunkResult=} obj
 * @returns {io.testable.agent.AgentChunkResult}
 */
io.testable.agent.AgentChunkResult.getSizePrefixedRootAsAgentChunkResult = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentChunkResult).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentChunkResult.prototype.connectionId = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentChunkResult.prototype.threadId = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentChunkResult.prototype.resource = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentChunkResult.prototype.url = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkResult.prototype.timestamp = function() {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {boolean}
 */
io.testable.agent.AgentChunkResult.prototype.firstReport = function() {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? !!this.bb.readInt8(this.bb_pos + offset) : false;
};

/**
 * @param {number} index
 * @param {io.testable.agent.AgentChunkMetric=} obj
 * @returns {io.testable.agent.AgentChunkMetric}
 */
io.testable.agent.AgentChunkResult.prototype.metrics = function(index, obj) {
  var offset = this.bb.__offset(this.bb_pos, 16);
  return offset ? (obj || new io.testable.agent.AgentChunkMetric).__init(this.bb.__indirect(this.bb.__vector(this.bb_pos + offset) + index * 4), this.bb) : null;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentChunkResult.prototype.metricsLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 16);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @param {io.testable.agent.AgentChunkTrace=} obj
 * @returns {io.testable.agent.AgentChunkTrace|null}
 */
io.testable.agent.AgentChunkResult.prototype.trace = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 18);
  return offset ? (obj || new io.testable.agent.AgentChunkTrace).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentChunkResult.prototype.iterationId = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 20);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkResult.prototype.createdAt = function() {
  var offset = this.bb.__offset(this.bb_pos, 22);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @param {number} index
 * @param {io.testable.agent.AgentSuiteResult=} obj
 * @returns {io.testable.agent.AgentSuiteResult}
 */
io.testable.agent.AgentChunkResult.prototype.testSuiteResults = function(index, obj) {
  var offset = this.bb.__offset(this.bb_pos, 24);
  return offset ? (obj || new io.testable.agent.AgentSuiteResult).__init(this.bb.__indirect(this.bb.__vector(this.bb_pos + offset) + index * 4), this.bb) : null;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentChunkResult.prototype.testSuiteResultsLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 24);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkResult.prototype.iteration = function() {
  var offset = this.bb.__offset(this.bb_pos, 26);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkResult.prototype.globalConcurrentClientIndex = function() {
  var offset = this.bb.__offset(this.bb_pos, 28);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkResult.prototype.regionalConcurrentClientIndex = function() {
  var offset = this.bb.__offset(this.bb_pos, 30);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {boolean}
 */
io.testable.agent.AgentChunkResult.prototype.hasClientIndices = function() {
  var offset = this.bb.__offset(this.bb_pos, 32);
  return offset ? !!this.bb.readInt8(this.bb_pos + offset) : false;
};

/**
 * @returns {boolean}
 */
io.testable.agent.AgentChunkResult.prototype.excludeFromCount = function() {
  var offset = this.bb.__offset(this.bb_pos, 34);
  return offset ? !!this.bb.readInt8(this.bb_pos + offset) : false;
};

/**
 * @param {io.testable.agent.Browser=} obj
 * @returns {io.testable.agent.Browser|null}
 */
io.testable.agent.AgentChunkResult.prototype.browser = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 36);
  return offset ? (obj || new io.testable.agent.Browser).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentChunkResult.prototype.device = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 38);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentChunkResult.startAgentChunkResult = function(builder) {
  builder.startObject(18);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} connectionIdOffset
 */
io.testable.agent.AgentChunkResult.addConnectionId = function(builder, connectionIdOffset) {
  builder.addFieldOffset(0, connectionIdOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} threadIdOffset
 */
io.testable.agent.AgentChunkResult.addThreadId = function(builder, threadIdOffset) {
  builder.addFieldOffset(1, threadIdOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} resourceOffset
 */
io.testable.agent.AgentChunkResult.addResource = function(builder, resourceOffset) {
  builder.addFieldOffset(2, resourceOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} urlOffset
 */
io.testable.agent.AgentChunkResult.addUrl = function(builder, urlOffset) {
  builder.addFieldOffset(3, urlOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} timestamp
 */
io.testable.agent.AgentChunkResult.addTimestamp = function(builder, timestamp) {
  builder.addFieldInt64(4, timestamp, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {boolean} firstReport
 */
io.testable.agent.AgentChunkResult.addFirstReport = function(builder, firstReport) {
  builder.addFieldInt8(5, +firstReport, +false);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} metricsOffset
 */
io.testable.agent.AgentChunkResult.addMetrics = function(builder, metricsOffset) {
  builder.addFieldOffset(6, metricsOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentChunkResult.createMetricsVector = function(builder, data) {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
io.testable.agent.AgentChunkResult.startMetricsVector = function(builder, numElems) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} traceOffset
 */
io.testable.agent.AgentChunkResult.addTrace = function(builder, traceOffset) {
  builder.addFieldOffset(7, traceOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} iterationIdOffset
 */
io.testable.agent.AgentChunkResult.addIterationId = function(builder, iterationIdOffset) {
  builder.addFieldOffset(8, iterationIdOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} createdAt
 */
io.testable.agent.AgentChunkResult.addCreatedAt = function(builder, createdAt) {
  builder.addFieldInt64(9, createdAt, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} testSuiteResultsOffset
 */
io.testable.agent.AgentChunkResult.addTestSuiteResults = function(builder, testSuiteResultsOffset) {
  builder.addFieldOffset(10, testSuiteResultsOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentChunkResult.createTestSuiteResultsVector = function(builder, data) {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
io.testable.agent.AgentChunkResult.startTestSuiteResultsVector = function(builder, numElems) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} iteration
 */
io.testable.agent.AgentChunkResult.addIteration = function(builder, iteration) {
  builder.addFieldInt64(11, iteration, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} globalConcurrentClientIndex
 */
io.testable.agent.AgentChunkResult.addGlobalConcurrentClientIndex = function(builder, globalConcurrentClientIndex) {
  builder.addFieldInt64(12, globalConcurrentClientIndex, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} regionalConcurrentClientIndex
 */
io.testable.agent.AgentChunkResult.addRegionalConcurrentClientIndex = function(builder, regionalConcurrentClientIndex) {
  builder.addFieldInt64(13, regionalConcurrentClientIndex, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {boolean} hasClientIndices
 */
io.testable.agent.AgentChunkResult.addHasClientIndices = function(builder, hasClientIndices) {
  builder.addFieldInt8(14, +hasClientIndices, +false);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {boolean} excludeFromCount
 */
io.testable.agent.AgentChunkResult.addExcludeFromCount = function(builder, excludeFromCount) {
  builder.addFieldInt8(15, +excludeFromCount, +false);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} browserOffset
 */
io.testable.agent.AgentChunkResult.addBrowser = function(builder, browserOffset) {
  builder.addFieldOffset(16, browserOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} deviceOffset
 */
io.testable.agent.AgentChunkResult.addDevice = function(builder, deviceOffset) {
  builder.addFieldOffset(17, deviceOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentChunkResult.endAgentChunkResult = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} connectionIdOffset
 * @param {flatbuffers.Offset} threadIdOffset
 * @param {flatbuffers.Offset} resourceOffset
 * @param {flatbuffers.Offset} urlOffset
 * @param {flatbuffers.Long} timestamp
 * @param {boolean} firstReport
 * @param {flatbuffers.Offset} metricsOffset
 * @param {flatbuffers.Offset} traceOffset
 * @param {flatbuffers.Offset} iterationIdOffset
 * @param {flatbuffers.Long} createdAt
 * @param {flatbuffers.Offset} testSuiteResultsOffset
 * @param {flatbuffers.Long} iteration
 * @param {flatbuffers.Long} globalConcurrentClientIndex
 * @param {flatbuffers.Long} regionalConcurrentClientIndex
 * @param {boolean} hasClientIndices
 * @param {boolean} excludeFromCount
 * @param {flatbuffers.Offset} browserOffset
 * @param {flatbuffers.Offset} deviceOffset
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentChunkResult.createAgentChunkResult = function(builder, connectionIdOffset, threadIdOffset, resourceOffset, urlOffset, timestamp, firstReport, metricsOffset, traceOffset, iterationIdOffset, createdAt, testSuiteResultsOffset, iteration, globalConcurrentClientIndex, regionalConcurrentClientIndex, hasClientIndices, excludeFromCount, browserOffset, deviceOffset) {
  io.testable.agent.AgentChunkResult.startAgentChunkResult(builder);
  io.testable.agent.AgentChunkResult.addConnectionId(builder, connectionIdOffset);
  io.testable.agent.AgentChunkResult.addThreadId(builder, threadIdOffset);
  io.testable.agent.AgentChunkResult.addResource(builder, resourceOffset);
  io.testable.agent.AgentChunkResult.addUrl(builder, urlOffset);
  io.testable.agent.AgentChunkResult.addTimestamp(builder, timestamp);
  io.testable.agent.AgentChunkResult.addFirstReport(builder, firstReport);
  io.testable.agent.AgentChunkResult.addMetrics(builder, metricsOffset);
  io.testable.agent.AgentChunkResult.addTrace(builder, traceOffset);
  io.testable.agent.AgentChunkResult.addIterationId(builder, iterationIdOffset);
  io.testable.agent.AgentChunkResult.addCreatedAt(builder, createdAt);
  io.testable.agent.AgentChunkResult.addTestSuiteResults(builder, testSuiteResultsOffset);
  io.testable.agent.AgentChunkResult.addIteration(builder, iteration);
  io.testable.agent.AgentChunkResult.addGlobalConcurrentClientIndex(builder, globalConcurrentClientIndex);
  io.testable.agent.AgentChunkResult.addRegionalConcurrentClientIndex(builder, regionalConcurrentClientIndex);
  io.testable.agent.AgentChunkResult.addHasClientIndices(builder, hasClientIndices);
  io.testable.agent.AgentChunkResult.addExcludeFromCount(builder, excludeFromCount);
  io.testable.agent.AgentChunkResult.addBrowser(builder, browserOffset);
  io.testable.agent.AgentChunkResult.addDevice(builder, deviceOffset);
  return io.testable.agent.AgentChunkResult.endAgentChunkResult(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentSuiteAction = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentSuiteAction}
 */
io.testable.agent.AgentSuiteAction.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentSuiteAction=} obj
 * @returns {io.testable.agent.AgentSuiteAction}
 */
io.testable.agent.AgentSuiteAction.getRootAsAgentSuiteAction = function(bb, obj) {
  return (obj || new io.testable.agent.AgentSuiteAction).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentSuiteAction=} obj
 * @returns {io.testable.agent.AgentSuiteAction}
 */
io.testable.agent.AgentSuiteAction.getSizePrefixedRootAsAgentSuiteAction = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentSuiteAction).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {io.testable.agent.AgentSuiteActionType}
 */
io.testable.agent.AgentSuiteAction.prototype.type = function() {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? /** @type {io.testable.agent.AgentSuiteActionType} */ (this.bb.readInt8(this.bb_pos + offset)) : io.testable.agent.AgentSuiteActionType.StartSuite;
};

/**
 * @param {io.testable.agent.AgentStartSuiteResult=} obj
 * @returns {io.testable.agent.AgentStartSuiteResult|null}
 */
io.testable.agent.AgentSuiteAction.prototype.startSuite = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? (obj || new io.testable.agent.AgentStartSuiteResult).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {io.testable.agent.AgentFinishSuiteResult=} obj
 * @returns {io.testable.agent.AgentFinishSuiteResult|null}
 */
io.testable.agent.AgentSuiteAction.prototype.finishSuite = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? (obj || new io.testable.agent.AgentFinishSuiteResult).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {io.testable.agent.AgentStartSuiteTestResult=} obj
 * @returns {io.testable.agent.AgentStartSuiteTestResult|null}
 */
io.testable.agent.AgentSuiteAction.prototype.startSuiteTest = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? (obj || new io.testable.agent.AgentStartSuiteTestResult).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {io.testable.agent.AgentFinishSuiteTestResult=} obj
 * @returns {io.testable.agent.AgentFinishSuiteTestResult|null}
 */
io.testable.agent.AgentSuiteAction.prototype.finishSuiteTest = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? (obj || new io.testable.agent.AgentFinishSuiteTestResult).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentSuiteAction.startAgentSuiteAction = function(builder) {
  builder.startObject(5);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {io.testable.agent.AgentSuiteActionType} type
 */
io.testable.agent.AgentSuiteAction.addType = function(builder, type) {
  builder.addFieldInt8(0, type, io.testable.agent.AgentSuiteActionType.StartSuite);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} startSuiteOffset
 */
io.testable.agent.AgentSuiteAction.addStartSuite = function(builder, startSuiteOffset) {
  builder.addFieldOffset(1, startSuiteOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} finishSuiteOffset
 */
io.testable.agent.AgentSuiteAction.addFinishSuite = function(builder, finishSuiteOffset) {
  builder.addFieldOffset(2, finishSuiteOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} startSuiteTestOffset
 */
io.testable.agent.AgentSuiteAction.addStartSuiteTest = function(builder, startSuiteTestOffset) {
  builder.addFieldOffset(3, startSuiteTestOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} finishSuiteTestOffset
 */
io.testable.agent.AgentSuiteAction.addFinishSuiteTest = function(builder, finishSuiteTestOffset) {
  builder.addFieldOffset(4, finishSuiteTestOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentSuiteAction.endAgentSuiteAction = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {io.testable.agent.AgentSuiteActionType} type
 * @param {flatbuffers.Offset} startSuiteOffset
 * @param {flatbuffers.Offset} finishSuiteOffset
 * @param {flatbuffers.Offset} startSuiteTestOffset
 * @param {flatbuffers.Offset} finishSuiteTestOffset
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentSuiteAction.createAgentSuiteAction = function(builder, type, startSuiteOffset, finishSuiteOffset, startSuiteTestOffset, finishSuiteTestOffset) {
  io.testable.agent.AgentSuiteAction.startAgentSuiteAction(builder);
  io.testable.agent.AgentSuiteAction.addType(builder, type);
  io.testable.agent.AgentSuiteAction.addStartSuite(builder, startSuiteOffset);
  io.testable.agent.AgentSuiteAction.addFinishSuite(builder, finishSuiteOffset);
  io.testable.agent.AgentSuiteAction.addStartSuiteTest(builder, startSuiteTestOffset);
  io.testable.agent.AgentSuiteAction.addFinishSuiteTest(builder, finishSuiteTestOffset);
  return io.testable.agent.AgentSuiteAction.endAgentSuiteAction(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentFile = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentFile}
 */
io.testable.agent.AgentFile.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentFile=} obj
 * @returns {io.testable.agent.AgentFile}
 */
io.testable.agent.AgentFile.getRootAsAgentFile = function(bb, obj) {
  return (obj || new io.testable.agent.AgentFile).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentFile=} obj
 * @returns {io.testable.agent.AgentFile}
 */
io.testable.agent.AgentFile.getSizePrefixedRootAsAgentFile = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentFile).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFile.prototype.name = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFile.prototype.contentType = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFile.prototype.charset = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {number} index
 * @returns {number}
 */
io.testable.agent.AgentFile.prototype.contents = function(index) {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.readInt8(this.bb.__vector(this.bb_pos + offset) + index) : 0;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentFile.prototype.contentsLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @returns {Int8Array}
 */
io.testable.agent.AgentFile.prototype.contentsArray = function() {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? new Int8Array(this.bb.bytes().buffer, this.bb.bytes().byteOffset + this.bb.__vector(this.bb_pos + offset), this.bb.__vector_len(this.bb_pos + offset)) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentFile.prototype.timestamp = function() {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentFile.prototype.concurrentClientIndex = function() {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentFile.prototype.globalConcurrentClientIndex = function() {
  var offset = this.bb.__offset(this.bb_pos, 16);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentFile.prototype.regionalConcurrentClientIndex = function() {
  var offset = this.bb.__offset(this.bb_pos, 18);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {number}
 */
io.testable.agent.AgentFile.prototype.iteration = function() {
  var offset = this.bb.__offset(this.bb_pos, 20);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFile.prototype.uuid = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 22);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {boolean}
 */
io.testable.agent.AgentFile.prototype.hasMorePackets = function() {
  var offset = this.bb.__offset(this.bb_pos, 24);
  return offset ? !!this.bb.readInt8(this.bb_pos + offset) : false;
};

/**
 * @returns {boolean}
 */
io.testable.agent.AgentFile.prototype.isFinalPacket = function() {
  var offset = this.bb.__offset(this.bb_pos, 26);
  return offset ? !!this.bb.readInt8(this.bb_pos + offset) : false;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentFile.prototype.packetNumber = function() {
  var offset = this.bb.__offset(this.bb_pos, 28);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFile.prototype.threadId = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 30);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFile.prototype.iterationId = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 32);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFile.prototype.relatedSuiteUuid = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 34);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFile.prototype.relatedSuiteTestUuid = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 36);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFile.prototype.relatedSuiteName = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 38);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFile.prototype.relatedSuiteTestName = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 40);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {io.testable.agent.Browser=} obj
 * @returns {io.testable.agent.Browser|null}
 */
io.testable.agent.AgentFile.prototype.browser = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 42);
  return offset ? (obj || new io.testable.agent.Browser).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFile.prototype.device = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 44);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFile.prototype.commandUuid = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 46);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFile.prototype.commandName = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 48);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentFile.prototype.sessionNum = function() {
  var offset = this.bb.__offset(this.bb_pos, 50);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentFile.startAgentFile = function(builder) {
  builder.startObject(24);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} nameOffset
 */
io.testable.agent.AgentFile.addName = function(builder, nameOffset) {
  builder.addFieldOffset(0, nameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} contentTypeOffset
 */
io.testable.agent.AgentFile.addContentType = function(builder, contentTypeOffset) {
  builder.addFieldOffset(1, contentTypeOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} charsetOffset
 */
io.testable.agent.AgentFile.addCharset = function(builder, charsetOffset) {
  builder.addFieldOffset(2, charsetOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} contentsOffset
 */
io.testable.agent.AgentFile.addContents = function(builder, contentsOffset) {
  builder.addFieldOffset(3, contentsOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<number>} data
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentFile.createContentsVector = function(builder, data) {
  builder.startVector(1, data.length, 1);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addInt8(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
io.testable.agent.AgentFile.startContentsVector = function(builder, numElems) {
  builder.startVector(1, numElems, 1);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} timestamp
 */
io.testable.agent.AgentFile.addTimestamp = function(builder, timestamp) {
  builder.addFieldInt64(4, timestamp, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} concurrentClientIndex
 */
io.testable.agent.AgentFile.addConcurrentClientIndex = function(builder, concurrentClientIndex) {
  builder.addFieldInt64(5, concurrentClientIndex, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} globalConcurrentClientIndex
 */
io.testable.agent.AgentFile.addGlobalConcurrentClientIndex = function(builder, globalConcurrentClientIndex) {
  builder.addFieldInt64(6, globalConcurrentClientIndex, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} regionalConcurrentClientIndex
 */
io.testable.agent.AgentFile.addRegionalConcurrentClientIndex = function(builder, regionalConcurrentClientIndex) {
  builder.addFieldInt64(7, regionalConcurrentClientIndex, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} iteration
 */
io.testable.agent.AgentFile.addIteration = function(builder, iteration) {
  builder.addFieldInt32(8, iteration, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} uuidOffset
 */
io.testable.agent.AgentFile.addUuid = function(builder, uuidOffset) {
  builder.addFieldOffset(9, uuidOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {boolean} hasMorePackets
 */
io.testable.agent.AgentFile.addHasMorePackets = function(builder, hasMorePackets) {
  builder.addFieldInt8(10, +hasMorePackets, +false);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {boolean} isFinalPacket
 */
io.testable.agent.AgentFile.addIsFinalPacket = function(builder, isFinalPacket) {
  builder.addFieldInt8(11, +isFinalPacket, +false);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} packetNumber
 */
io.testable.agent.AgentFile.addPacketNumber = function(builder, packetNumber) {
  builder.addFieldInt32(12, packetNumber, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} threadIdOffset
 */
io.testable.agent.AgentFile.addThreadId = function(builder, threadIdOffset) {
  builder.addFieldOffset(13, threadIdOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} iterationIdOffset
 */
io.testable.agent.AgentFile.addIterationId = function(builder, iterationIdOffset) {
  builder.addFieldOffset(14, iterationIdOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} relatedSuiteUuidOffset
 */
io.testable.agent.AgentFile.addRelatedSuiteUuid = function(builder, relatedSuiteUuidOffset) {
  builder.addFieldOffset(15, relatedSuiteUuidOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} relatedSuiteTestUuidOffset
 */
io.testable.agent.AgentFile.addRelatedSuiteTestUuid = function(builder, relatedSuiteTestUuidOffset) {
  builder.addFieldOffset(16, relatedSuiteTestUuidOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} relatedSuiteNameOffset
 */
io.testable.agent.AgentFile.addRelatedSuiteName = function(builder, relatedSuiteNameOffset) {
  builder.addFieldOffset(17, relatedSuiteNameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} relatedSuiteTestNameOffset
 */
io.testable.agent.AgentFile.addRelatedSuiteTestName = function(builder, relatedSuiteTestNameOffset) {
  builder.addFieldOffset(18, relatedSuiteTestNameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} browserOffset
 */
io.testable.agent.AgentFile.addBrowser = function(builder, browserOffset) {
  builder.addFieldOffset(19, browserOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} deviceOffset
 */
io.testable.agent.AgentFile.addDevice = function(builder, deviceOffset) {
  builder.addFieldOffset(20, deviceOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} commandUuidOffset
 */
io.testable.agent.AgentFile.addCommandUuid = function(builder, commandUuidOffset) {
  builder.addFieldOffset(21, commandUuidOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} commandNameOffset
 */
io.testable.agent.AgentFile.addCommandName = function(builder, commandNameOffset) {
  builder.addFieldOffset(22, commandNameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} sessionNum
 */
io.testable.agent.AgentFile.addSessionNum = function(builder, sessionNum) {
  builder.addFieldInt32(23, sessionNum, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentFile.endAgentFile = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} nameOffset
 * @param {flatbuffers.Offset} contentTypeOffset
 * @param {flatbuffers.Offset} charsetOffset
 * @param {flatbuffers.Offset} contentsOffset
 * @param {flatbuffers.Long} timestamp
 * @param {flatbuffers.Long} concurrentClientIndex
 * @param {flatbuffers.Long} globalConcurrentClientIndex
 * @param {flatbuffers.Long} regionalConcurrentClientIndex
 * @param {number} iteration
 * @param {flatbuffers.Offset} uuidOffset
 * @param {boolean} hasMorePackets
 * @param {boolean} isFinalPacket
 * @param {number} packetNumber
 * @param {flatbuffers.Offset} threadIdOffset
 * @param {flatbuffers.Offset} iterationIdOffset
 * @param {flatbuffers.Offset} relatedSuiteUuidOffset
 * @param {flatbuffers.Offset} relatedSuiteTestUuidOffset
 * @param {flatbuffers.Offset} relatedSuiteNameOffset
 * @param {flatbuffers.Offset} relatedSuiteTestNameOffset
 * @param {flatbuffers.Offset} browserOffset
 * @param {flatbuffers.Offset} deviceOffset
 * @param {flatbuffers.Offset} commandUuidOffset
 * @param {flatbuffers.Offset} commandNameOffset
 * @param {number} sessionNum
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentFile.createAgentFile = function(builder, nameOffset, contentTypeOffset, charsetOffset, contentsOffset, timestamp, concurrentClientIndex, globalConcurrentClientIndex, regionalConcurrentClientIndex, iteration, uuidOffset, hasMorePackets, isFinalPacket, packetNumber, threadIdOffset, iterationIdOffset, relatedSuiteUuidOffset, relatedSuiteTestUuidOffset, relatedSuiteNameOffset, relatedSuiteTestNameOffset, browserOffset, deviceOffset, commandUuidOffset, commandNameOffset, sessionNum) {
  io.testable.agent.AgentFile.startAgentFile(builder);
  io.testable.agent.AgentFile.addName(builder, nameOffset);
  io.testable.agent.AgentFile.addContentType(builder, contentTypeOffset);
  io.testable.agent.AgentFile.addCharset(builder, charsetOffset);
  io.testable.agent.AgentFile.addContents(builder, contentsOffset);
  io.testable.agent.AgentFile.addTimestamp(builder, timestamp);
  io.testable.agent.AgentFile.addConcurrentClientIndex(builder, concurrentClientIndex);
  io.testable.agent.AgentFile.addGlobalConcurrentClientIndex(builder, globalConcurrentClientIndex);
  io.testable.agent.AgentFile.addRegionalConcurrentClientIndex(builder, regionalConcurrentClientIndex);
  io.testable.agent.AgentFile.addIteration(builder, iteration);
  io.testable.agent.AgentFile.addUuid(builder, uuidOffset);
  io.testable.agent.AgentFile.addHasMorePackets(builder, hasMorePackets);
  io.testable.agent.AgentFile.addIsFinalPacket(builder, isFinalPacket);
  io.testable.agent.AgentFile.addPacketNumber(builder, packetNumber);
  io.testable.agent.AgentFile.addThreadId(builder, threadIdOffset);
  io.testable.agent.AgentFile.addIterationId(builder, iterationIdOffset);
  io.testable.agent.AgentFile.addRelatedSuiteUuid(builder, relatedSuiteUuidOffset);
  io.testable.agent.AgentFile.addRelatedSuiteTestUuid(builder, relatedSuiteTestUuidOffset);
  io.testable.agent.AgentFile.addRelatedSuiteName(builder, relatedSuiteNameOffset);
  io.testable.agent.AgentFile.addRelatedSuiteTestName(builder, relatedSuiteTestNameOffset);
  io.testable.agent.AgentFile.addBrowser(builder, browserOffset);
  io.testable.agent.AgentFile.addDevice(builder, deviceOffset);
  io.testable.agent.AgentFile.addCommandUuid(builder, commandUuidOffset);
  io.testable.agent.AgentFile.addCommandName(builder, commandNameOffset);
  io.testable.agent.AgentFile.addSessionNum(builder, sessionNum);
  return io.testable.agent.AgentFile.endAgentFile(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentChunkTrace = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentChunkTrace}
 */
io.testable.agent.AgentChunkTrace.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentChunkTrace=} obj
 * @returns {io.testable.agent.AgentChunkTrace}
 */
io.testable.agent.AgentChunkTrace.getRootAsAgentChunkTrace = function(bb, obj) {
  return (obj || new io.testable.agent.AgentChunkTrace).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentChunkTrace=} obj
 * @returns {io.testable.agent.AgentChunkTrace}
 */
io.testable.agent.AgentChunkTrace.getSizePrefixedRootAsAgentChunkTrace = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentChunkTrace).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentChunkTrace.prototype.status = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkTrace.prototype.length = function() {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @param {number} index
 * @param {io.testable.agent.AgentChunkTracePacket=} obj
 * @returns {io.testable.agent.AgentChunkTracePacket}
 */
io.testable.agent.AgentChunkTrace.prototype.packets = function(index, obj) {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? (obj || new io.testable.agent.AgentChunkTracePacket).__init(this.bb.__indirect(this.bb.__vector(this.bb_pos + offset) + index * 4), this.bb) : null;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentChunkTrace.prototype.packetsLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentChunkTrace.prototype.statusMsg = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentChunkTrace.prototype.uuid = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {boolean}
 */
io.testable.agent.AgentChunkTrace.prototype.tooLarge = function() {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? !!this.bb.readInt8(this.bb_pos + offset) : false;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentChunkTrace.startAgentChunkTrace = function(builder) {
  builder.startObject(6);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} statusOffset
 */
io.testable.agent.AgentChunkTrace.addStatus = function(builder, statusOffset) {
  builder.addFieldOffset(0, statusOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} length
 */
io.testable.agent.AgentChunkTrace.addLength = function(builder, length) {
  builder.addFieldInt64(1, length, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} packetsOffset
 */
io.testable.agent.AgentChunkTrace.addPackets = function(builder, packetsOffset) {
  builder.addFieldOffset(2, packetsOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentChunkTrace.createPacketsVector = function(builder, data) {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
io.testable.agent.AgentChunkTrace.startPacketsVector = function(builder, numElems) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} statusMsgOffset
 */
io.testable.agent.AgentChunkTrace.addStatusMsg = function(builder, statusMsgOffset) {
  builder.addFieldOffset(3, statusMsgOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} uuidOffset
 */
io.testable.agent.AgentChunkTrace.addUuid = function(builder, uuidOffset) {
  builder.addFieldOffset(4, uuidOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {boolean} tooLarge
 */
io.testable.agent.AgentChunkTrace.addTooLarge = function(builder, tooLarge) {
  builder.addFieldInt8(5, +tooLarge, +false);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentChunkTrace.endAgentChunkTrace = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} statusOffset
 * @param {flatbuffers.Long} length
 * @param {flatbuffers.Offset} packetsOffset
 * @param {flatbuffers.Offset} statusMsgOffset
 * @param {flatbuffers.Offset} uuidOffset
 * @param {boolean} tooLarge
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentChunkTrace.createAgentChunkTrace = function(builder, statusOffset, length, packetsOffset, statusMsgOffset, uuidOffset, tooLarge) {
  io.testable.agent.AgentChunkTrace.startAgentChunkTrace(builder);
  io.testable.agent.AgentChunkTrace.addStatus(builder, statusOffset);
  io.testable.agent.AgentChunkTrace.addLength(builder, length);
  io.testable.agent.AgentChunkTrace.addPackets(builder, packetsOffset);
  io.testable.agent.AgentChunkTrace.addStatusMsg(builder, statusMsgOffset);
  io.testable.agent.AgentChunkTrace.addUuid(builder, uuidOffset);
  io.testable.agent.AgentChunkTrace.addTooLarge(builder, tooLarge);
  return io.testable.agent.AgentChunkTrace.endAgentChunkTrace(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentChunkTracePacket = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentChunkTracePacket}
 */
io.testable.agent.AgentChunkTracePacket.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentChunkTracePacket=} obj
 * @returns {io.testable.agent.AgentChunkTracePacket}
 */
io.testable.agent.AgentChunkTracePacket.getRootAsAgentChunkTracePacket = function(bb, obj) {
  return (obj || new io.testable.agent.AgentChunkTracePacket).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentChunkTracePacket=} obj
 * @returns {io.testable.agent.AgentChunkTracePacket}
 */
io.testable.agent.AgentChunkTracePacket.getSizePrefixedRootAsAgentChunkTracePacket = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentChunkTracePacket).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkTracePacket.prototype.timestamp = function() {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkTracePacket.prototype.lastTimestamp = function() {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentChunkTracePacket.prototype.type = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentChunkTracePacket.prototype.headers = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {io.testable.agent.AgentChunkTracePacketBody=} obj
 * @returns {io.testable.agent.AgentChunkTracePacketBody|null}
 */
io.testable.agent.AgentChunkTracePacket.prototype.body = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? (obj || new io.testable.agent.AgentChunkTracePacketBody).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @returns {boolean}
 */
io.testable.agent.AgentChunkTracePacket.prototype.bodyTooLarge = function() {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? !!this.bb.readInt8(this.bb_pos + offset) : false;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkTracePacket.prototype.length = function() {
  var offset = this.bb.__offset(this.bb_pos, 16);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentChunkTracePacket.startAgentChunkTracePacket = function(builder) {
  builder.startObject(7);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} timestamp
 */
io.testable.agent.AgentChunkTracePacket.addTimestamp = function(builder, timestamp) {
  builder.addFieldInt64(0, timestamp, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} lastTimestamp
 */
io.testable.agent.AgentChunkTracePacket.addLastTimestamp = function(builder, lastTimestamp) {
  builder.addFieldInt64(1, lastTimestamp, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} typeOffset
 */
io.testable.agent.AgentChunkTracePacket.addType = function(builder, typeOffset) {
  builder.addFieldOffset(2, typeOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} headersOffset
 */
io.testable.agent.AgentChunkTracePacket.addHeaders = function(builder, headersOffset) {
  builder.addFieldOffset(3, headersOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} bodyOffset
 */
io.testable.agent.AgentChunkTracePacket.addBody = function(builder, bodyOffset) {
  builder.addFieldOffset(4, bodyOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {boolean} bodyTooLarge
 */
io.testable.agent.AgentChunkTracePacket.addBodyTooLarge = function(builder, bodyTooLarge) {
  builder.addFieldInt8(5, +bodyTooLarge, +false);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} length
 */
io.testable.agent.AgentChunkTracePacket.addLength = function(builder, length) {
  builder.addFieldInt64(6, length, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentChunkTracePacket.endAgentChunkTracePacket = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} timestamp
 * @param {flatbuffers.Long} lastTimestamp
 * @param {flatbuffers.Offset} typeOffset
 * @param {flatbuffers.Offset} headersOffset
 * @param {flatbuffers.Offset} bodyOffset
 * @param {boolean} bodyTooLarge
 * @param {flatbuffers.Long} length
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentChunkTracePacket.createAgentChunkTracePacket = function(builder, timestamp, lastTimestamp, typeOffset, headersOffset, bodyOffset, bodyTooLarge, length) {
  io.testable.agent.AgentChunkTracePacket.startAgentChunkTracePacket(builder);
  io.testable.agent.AgentChunkTracePacket.addTimestamp(builder, timestamp);
  io.testable.agent.AgentChunkTracePacket.addLastTimestamp(builder, lastTimestamp);
  io.testable.agent.AgentChunkTracePacket.addType(builder, typeOffset);
  io.testable.agent.AgentChunkTracePacket.addHeaders(builder, headersOffset);
  io.testable.agent.AgentChunkTracePacket.addBody(builder, bodyOffset);
  io.testable.agent.AgentChunkTracePacket.addBodyTooLarge(builder, bodyTooLarge);
  io.testable.agent.AgentChunkTracePacket.addLength(builder, length);
  return io.testable.agent.AgentChunkTracePacket.endAgentChunkTracePacket(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentChunkTracePacketBody = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentChunkTracePacketBody}
 */
io.testable.agent.AgentChunkTracePacketBody.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentChunkTracePacketBody=} obj
 * @returns {io.testable.agent.AgentChunkTracePacketBody}
 */
io.testable.agent.AgentChunkTracePacketBody.getRootAsAgentChunkTracePacketBody = function(bb, obj) {
  return (obj || new io.testable.agent.AgentChunkTracePacketBody).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentChunkTracePacketBody=} obj
 * @returns {io.testable.agent.AgentChunkTracePacketBody}
 */
io.testable.agent.AgentChunkTracePacketBody.getSizePrefixedRootAsAgentChunkTracePacketBody = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentChunkTracePacketBody).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentChunkTracePacketBody.prototype.type = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {number} index
 * @returns {number}
 */
io.testable.agent.AgentChunkTracePacketBody.prototype.data = function(index) {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.readInt8(this.bb.__vector(this.bb_pos + offset) + index) : 0;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentChunkTracePacketBody.prototype.dataLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @returns {Int8Array}
 */
io.testable.agent.AgentChunkTracePacketBody.prototype.dataArray = function() {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? new Int8Array(this.bb.bytes().buffer, this.bb.bytes().byteOffset + this.bb.__vector(this.bb_pos + offset), this.bb.__vector_len(this.bb_pos + offset)) : null;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentChunkTracePacketBody.startAgentChunkTracePacketBody = function(builder) {
  builder.startObject(2);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} typeOffset
 */
io.testable.agent.AgentChunkTracePacketBody.addType = function(builder, typeOffset) {
  builder.addFieldOffset(0, typeOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} dataOffset
 */
io.testable.agent.AgentChunkTracePacketBody.addData = function(builder, dataOffset) {
  builder.addFieldOffset(1, dataOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<number>} data
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentChunkTracePacketBody.createDataVector = function(builder, data) {
  builder.startVector(1, data.length, 1);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addInt8(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
io.testable.agent.AgentChunkTracePacketBody.startDataVector = function(builder, numElems) {
  builder.startVector(1, numElems, 1);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentChunkTracePacketBody.endAgentChunkTracePacketBody = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} typeOffset
 * @param {flatbuffers.Offset} dataOffset
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentChunkTracePacketBody.createAgentChunkTracePacketBody = function(builder, typeOffset, dataOffset) {
  io.testable.agent.AgentChunkTracePacketBody.startAgentChunkTracePacketBody(builder);
  io.testable.agent.AgentChunkTracePacketBody.addType(builder, typeOffset);
  io.testable.agent.AgentChunkTracePacketBody.addData(builder, dataOffset);
  return io.testable.agent.AgentChunkTracePacketBody.endAgentChunkTracePacketBody(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentChunkMetric = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentChunkMetric}
 */
io.testable.agent.AgentChunkMetric.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentChunkMetric=} obj
 * @returns {io.testable.agent.AgentChunkMetric}
 */
io.testable.agent.AgentChunkMetric.getRootAsAgentChunkMetric = function(bb, obj) {
  return (obj || new io.testable.agent.AgentChunkMetric).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentChunkMetric=} obj
 * @returns {io.testable.agent.AgentChunkMetric}
 */
io.testable.agent.AgentChunkMetric.getSizePrefixedRootAsAgentChunkMetric = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentChunkMetric).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentChunkMetric.prototype.name = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {io.testable.agent.MetricType}
 */
io.testable.agent.AgentChunkMetric.prototype.metricType = function() {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? /** @type {io.testable.agent.MetricType} */ (this.bb.readInt8(this.bb_pos + offset)) : io.testable.agent.MetricType.Timing;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentChunkMetric.prototype.metricKey = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentChunkMetric.prototype.metricValue = function() {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentChunkMetric.prototype.metricUnits = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentChunkMetric.prototype.ns = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentChunkMetric.startAgentChunkMetric = function(builder) {
  builder.startObject(6);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} nameOffset
 */
io.testable.agent.AgentChunkMetric.addName = function(builder, nameOffset) {
  builder.addFieldOffset(0, nameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {io.testable.agent.MetricType} metricType
 */
io.testable.agent.AgentChunkMetric.addMetricType = function(builder, metricType) {
  builder.addFieldInt8(1, metricType, io.testable.agent.MetricType.Timing);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} metricKeyOffset
 */
io.testable.agent.AgentChunkMetric.addMetricKey = function(builder, metricKeyOffset) {
  builder.addFieldOffset(2, metricKeyOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} metricValue
 */
io.testable.agent.AgentChunkMetric.addMetricValue = function(builder, metricValue) {
  builder.addFieldInt64(3, metricValue, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} metricUnitsOffset
 */
io.testable.agent.AgentChunkMetric.addMetricUnits = function(builder, metricUnitsOffset) {
  builder.addFieldOffset(4, metricUnitsOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} nsOffset
 */
io.testable.agent.AgentChunkMetric.addNs = function(builder, nsOffset) {
  builder.addFieldOffset(5, nsOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentChunkMetric.endAgentChunkMetric = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} nameOffset
 * @param {io.testable.agent.MetricType} metricType
 * @param {flatbuffers.Offset} metricKeyOffset
 * @param {flatbuffers.Long} metricValue
 * @param {flatbuffers.Offset} metricUnitsOffset
 * @param {flatbuffers.Offset} nsOffset
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentChunkMetric.createAgentChunkMetric = function(builder, nameOffset, metricType, metricKeyOffset, metricValue, metricUnitsOffset, nsOffset) {
  io.testable.agent.AgentChunkMetric.startAgentChunkMetric(builder);
  io.testable.agent.AgentChunkMetric.addName(builder, nameOffset);
  io.testable.agent.AgentChunkMetric.addMetricType(builder, metricType);
  io.testable.agent.AgentChunkMetric.addMetricKey(builder, metricKeyOffset);
  io.testable.agent.AgentChunkMetric.addMetricValue(builder, metricValue);
  io.testable.agent.AgentChunkMetric.addMetricUnits(builder, metricUnitsOffset);
  io.testable.agent.AgentChunkMetric.addNs(builder, nsOffset);
  return io.testable.agent.AgentChunkMetric.endAgentChunkMetric(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentLog = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentLog}
 */
io.testable.agent.AgentLog.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentLog=} obj
 * @returns {io.testable.agent.AgentLog}
 */
io.testable.agent.AgentLog.getRootAsAgentLog = function(bb, obj) {
  return (obj || new io.testable.agent.AgentLog).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentLog=} obj
 * @returns {io.testable.agent.AgentLog}
 */
io.testable.agent.AgentLog.getSizePrefixedRootAsAgentLog = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentLog).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentLog.prototype.execution = function() {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentLog.prototype.executionRegion = function() {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentLog.prototype.chunk = function() {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentLog.prototype.agentId = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentLog.prototype.regionName = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {number} index
 * @param {io.testable.agent.AgentLogMessage=} obj
 * @returns {io.testable.agent.AgentLogMessage}
 */
io.testable.agent.AgentLog.prototype.messages = function(index, obj) {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? (obj || new io.testable.agent.AgentLogMessage).__init(this.bb.__indirect(this.bb.__vector(this.bb_pos + offset) + index * 4), this.bb) : null;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentLog.prototype.messagesLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentLog.prototype.org = function() {
  var offset = this.bb.__offset(this.bb_pos, 16);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {number}
 */
io.testable.agent.AgentLog.prototype.client = function() {
  var offset = this.bb.__offset(this.bb_pos, 18);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentLog.prototype.logGroup = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 20);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentLog.prototype.regionId = function() {
  var offset = this.bb.__offset(this.bb_pos, 22);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {number}
 */
io.testable.agent.AgentLog.prototype.iteration = function() {
  var offset = this.bb.__offset(this.bb_pos, 24);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentLog.startAgentLog = function(builder) {
  builder.startObject(11);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} execution
 */
io.testable.agent.AgentLog.addExecution = function(builder, execution) {
  builder.addFieldInt64(0, execution, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} executionRegion
 */
io.testable.agent.AgentLog.addExecutionRegion = function(builder, executionRegion) {
  builder.addFieldInt64(1, executionRegion, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} chunk
 */
io.testable.agent.AgentLog.addChunk = function(builder, chunk) {
  builder.addFieldInt64(2, chunk, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} agentIdOffset
 */
io.testable.agent.AgentLog.addAgentId = function(builder, agentIdOffset) {
  builder.addFieldOffset(3, agentIdOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} regionNameOffset
 */
io.testable.agent.AgentLog.addRegionName = function(builder, regionNameOffset) {
  builder.addFieldOffset(4, regionNameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} messagesOffset
 */
io.testable.agent.AgentLog.addMessages = function(builder, messagesOffset) {
  builder.addFieldOffset(5, messagesOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentLog.createMessagesVector = function(builder, data) {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
io.testable.agent.AgentLog.startMessagesVector = function(builder, numElems) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} org
 */
io.testable.agent.AgentLog.addOrg = function(builder, org) {
  builder.addFieldInt64(6, org, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} client
 */
io.testable.agent.AgentLog.addClient = function(builder, client) {
  builder.addFieldInt32(7, client, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} logGroupOffset
 */
io.testable.agent.AgentLog.addLogGroup = function(builder, logGroupOffset) {
  builder.addFieldOffset(8, logGroupOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} regionId
 */
io.testable.agent.AgentLog.addRegionId = function(builder, regionId) {
  builder.addFieldInt64(9, regionId, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} iteration
 */
io.testable.agent.AgentLog.addIteration = function(builder, iteration) {
  builder.addFieldInt32(10, iteration, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentLog.endAgentLog = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} execution
 * @param {flatbuffers.Long} executionRegion
 * @param {flatbuffers.Long} chunk
 * @param {flatbuffers.Offset} agentIdOffset
 * @param {flatbuffers.Offset} regionNameOffset
 * @param {flatbuffers.Offset} messagesOffset
 * @param {flatbuffers.Long} org
 * @param {number} client
 * @param {flatbuffers.Offset} logGroupOffset
 * @param {flatbuffers.Long} regionId
 * @param {number} iteration
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentLog.createAgentLog = function(builder, execution, executionRegion, chunk, agentIdOffset, regionNameOffset, messagesOffset, org, client, logGroupOffset, regionId, iteration) {
  io.testable.agent.AgentLog.startAgentLog(builder);
  io.testable.agent.AgentLog.addExecution(builder, execution);
  io.testable.agent.AgentLog.addExecutionRegion(builder, executionRegion);
  io.testable.agent.AgentLog.addChunk(builder, chunk);
  io.testable.agent.AgentLog.addAgentId(builder, agentIdOffset);
  io.testable.agent.AgentLog.addRegionName(builder, regionNameOffset);
  io.testable.agent.AgentLog.addMessages(builder, messagesOffset);
  io.testable.agent.AgentLog.addOrg(builder, org);
  io.testable.agent.AgentLog.addClient(builder, client);
  io.testable.agent.AgentLog.addLogGroup(builder, logGroupOffset);
  io.testable.agent.AgentLog.addRegionId(builder, regionId);
  io.testable.agent.AgentLog.addIteration(builder, iteration);
  return io.testable.agent.AgentLog.endAgentLog(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentLogMessage = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentLogMessage}
 */
io.testable.agent.AgentLogMessage.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentLogMessage=} obj
 * @returns {io.testable.agent.AgentLogMessage}
 */
io.testable.agent.AgentLogMessage.getRootAsAgentLogMessage = function(bb, obj) {
  return (obj || new io.testable.agent.AgentLogMessage).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentLogMessage=} obj
 * @returns {io.testable.agent.AgentLogMessage}
 */
io.testable.agent.AgentLogMessage.getSizePrefixedRootAsAgentLogMessage = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentLogMessage).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {io.testable.agent.LogLevel}
 */
io.testable.agent.AgentLogMessage.prototype.level = function() {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? /** @type {io.testable.agent.LogLevel} */ (this.bb.readInt8(this.bb_pos + offset)) : io.testable.agent.LogLevel.Error;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentLogMessage.prototype.message = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentLogMessage.prototype.timestamp = function() {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentLogMessage.startAgentLogMessage = function(builder) {
  builder.startObject(3);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {io.testable.agent.LogLevel} level
 */
io.testable.agent.AgentLogMessage.addLevel = function(builder, level) {
  builder.addFieldInt8(0, level, io.testable.agent.LogLevel.Error);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} messageOffset
 */
io.testable.agent.AgentLogMessage.addMessage = function(builder, messageOffset) {
  builder.addFieldOffset(1, messageOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} timestamp
 */
io.testable.agent.AgentLogMessage.addTimestamp = function(builder, timestamp) {
  builder.addFieldInt64(2, timestamp, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentLogMessage.endAgentLogMessage = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {io.testable.agent.LogLevel} level
 * @param {flatbuffers.Offset} messageOffset
 * @param {flatbuffers.Long} timestamp
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentLogMessage.createAgentLogMessage = function(builder, level, messageOffset, timestamp) {
  io.testable.agent.AgentLogMessage.startAgentLogMessage(builder);
  io.testable.agent.AgentLogMessage.addLevel(builder, level);
  io.testable.agent.AgentLogMessage.addMessage(builder, messageOffset);
  io.testable.agent.AgentLogMessage.addTimestamp(builder, timestamp);
  return io.testable.agent.AgentLogMessage.endAgentLogMessage(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentSuiteResult = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentSuiteResult}
 */
io.testable.agent.AgentSuiteResult.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentSuiteResult=} obj
 * @returns {io.testable.agent.AgentSuiteResult}
 */
io.testable.agent.AgentSuiteResult.getRootAsAgentSuiteResult = function(bb, obj) {
  return (obj || new io.testable.agent.AgentSuiteResult).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentSuiteResult=} obj
 * @returns {io.testable.agent.AgentSuiteResult}
 */
io.testable.agent.AgentSuiteResult.getSizePrefixedRootAsAgentSuiteResult = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentSuiteResult).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentSuiteResult.prototype.name = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentSuiteResult.prototype.started = function() {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentSuiteResult.prototype.finished = function() {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentSuiteResult.prototype.duration = function() {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentSuiteResult.prototype.concurrentClientIndex = function() {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentSuiteResult.prototype.globalConcurrentClientIndex = function() {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentSuiteResult.prototype.regionalConcurrentClientIndex = function() {
  var offset = this.bb.__offset(this.bb_pos, 16);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {number}
 */
io.testable.agent.AgentSuiteResult.prototype.iteration = function() {
  var offset = this.bb.__offset(this.bb_pos, 18);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentSuiteResult.prototype.iterationId = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 20);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentSuiteResult.prototype.threadId = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 22);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {number} index
 * @param {io.testable.agent.AgentSuiteTestResult=} obj
 * @returns {io.testable.agent.AgentSuiteTestResult}
 */
io.testable.agent.AgentSuiteResult.prototype.testResults = function(index, obj) {
  var offset = this.bb.__offset(this.bb_pos, 24);
  return offset ? (obj || new io.testable.agent.AgentSuiteTestResult).__init(this.bb.__indirect(this.bb.__vector(this.bb_pos + offset) + index * 4), this.bb) : null;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentSuiteResult.prototype.testResultsLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 24);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @param {io.testable.agent.Browser=} obj
 * @returns {io.testable.agent.Browser|null}
 */
io.testable.agent.AgentSuiteResult.prototype.browser = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 26);
  return offset ? (obj || new io.testable.agent.Browser).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentSuiteResult.prototype.device = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 28);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentSuiteResult.startAgentSuiteResult = function(builder) {
  builder.startObject(13);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} nameOffset
 */
io.testable.agent.AgentSuiteResult.addName = function(builder, nameOffset) {
  builder.addFieldOffset(0, nameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} started
 */
io.testable.agent.AgentSuiteResult.addStarted = function(builder, started) {
  builder.addFieldInt64(1, started, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} finished
 */
io.testable.agent.AgentSuiteResult.addFinished = function(builder, finished) {
  builder.addFieldInt64(2, finished, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} duration
 */
io.testable.agent.AgentSuiteResult.addDuration = function(builder, duration) {
  builder.addFieldInt64(3, duration, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} concurrentClientIndex
 */
io.testable.agent.AgentSuiteResult.addConcurrentClientIndex = function(builder, concurrentClientIndex) {
  builder.addFieldInt64(4, concurrentClientIndex, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} globalConcurrentClientIndex
 */
io.testable.agent.AgentSuiteResult.addGlobalConcurrentClientIndex = function(builder, globalConcurrentClientIndex) {
  builder.addFieldInt64(5, globalConcurrentClientIndex, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} regionalConcurrentClientIndex
 */
io.testable.agent.AgentSuiteResult.addRegionalConcurrentClientIndex = function(builder, regionalConcurrentClientIndex) {
  builder.addFieldInt64(6, regionalConcurrentClientIndex, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} iteration
 */
io.testable.agent.AgentSuiteResult.addIteration = function(builder, iteration) {
  builder.addFieldInt32(7, iteration, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} iterationIdOffset
 */
io.testable.agent.AgentSuiteResult.addIterationId = function(builder, iterationIdOffset) {
  builder.addFieldOffset(8, iterationIdOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} threadIdOffset
 */
io.testable.agent.AgentSuiteResult.addThreadId = function(builder, threadIdOffset) {
  builder.addFieldOffset(9, threadIdOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} testResultsOffset
 */
io.testable.agent.AgentSuiteResult.addTestResults = function(builder, testResultsOffset) {
  builder.addFieldOffset(10, testResultsOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentSuiteResult.createTestResultsVector = function(builder, data) {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
io.testable.agent.AgentSuiteResult.startTestResultsVector = function(builder, numElems) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} browserOffset
 */
io.testable.agent.AgentSuiteResult.addBrowser = function(builder, browserOffset) {
  builder.addFieldOffset(11, browserOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} deviceOffset
 */
io.testable.agent.AgentSuiteResult.addDevice = function(builder, deviceOffset) {
  builder.addFieldOffset(12, deviceOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentSuiteResult.endAgentSuiteResult = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} nameOffset
 * @param {flatbuffers.Long} started
 * @param {flatbuffers.Long} finished
 * @param {flatbuffers.Long} duration
 * @param {flatbuffers.Long} concurrentClientIndex
 * @param {flatbuffers.Long} globalConcurrentClientIndex
 * @param {flatbuffers.Long} regionalConcurrentClientIndex
 * @param {number} iteration
 * @param {flatbuffers.Offset} iterationIdOffset
 * @param {flatbuffers.Offset} threadIdOffset
 * @param {flatbuffers.Offset} testResultsOffset
 * @param {flatbuffers.Offset} browserOffset
 * @param {flatbuffers.Offset} deviceOffset
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentSuiteResult.createAgentSuiteResult = function(builder, nameOffset, started, finished, duration, concurrentClientIndex, globalConcurrentClientIndex, regionalConcurrentClientIndex, iteration, iterationIdOffset, threadIdOffset, testResultsOffset, browserOffset, deviceOffset) {
  io.testable.agent.AgentSuiteResult.startAgentSuiteResult(builder);
  io.testable.agent.AgentSuiteResult.addName(builder, nameOffset);
  io.testable.agent.AgentSuiteResult.addStarted(builder, started);
  io.testable.agent.AgentSuiteResult.addFinished(builder, finished);
  io.testable.agent.AgentSuiteResult.addDuration(builder, duration);
  io.testable.agent.AgentSuiteResult.addConcurrentClientIndex(builder, concurrentClientIndex);
  io.testable.agent.AgentSuiteResult.addGlobalConcurrentClientIndex(builder, globalConcurrentClientIndex);
  io.testable.agent.AgentSuiteResult.addRegionalConcurrentClientIndex(builder, regionalConcurrentClientIndex);
  io.testable.agent.AgentSuiteResult.addIteration(builder, iteration);
  io.testable.agent.AgentSuiteResult.addIterationId(builder, iterationIdOffset);
  io.testable.agent.AgentSuiteResult.addThreadId(builder, threadIdOffset);
  io.testable.agent.AgentSuiteResult.addTestResults(builder, testResultsOffset);
  io.testable.agent.AgentSuiteResult.addBrowser(builder, browserOffset);
  io.testable.agent.AgentSuiteResult.addDevice(builder, deviceOffset);
  return io.testable.agent.AgentSuiteResult.endAgentSuiteResult(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentSuiteTestResult = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentSuiteTestResult}
 */
io.testable.agent.AgentSuiteTestResult.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentSuiteTestResult=} obj
 * @returns {io.testable.agent.AgentSuiteTestResult}
 */
io.testable.agent.AgentSuiteTestResult.getRootAsAgentSuiteTestResult = function(bb, obj) {
  return (obj || new io.testable.agent.AgentSuiteTestResult).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentSuiteTestResult=} obj
 * @returns {io.testable.agent.AgentSuiteTestResult}
 */
io.testable.agent.AgentSuiteTestResult.getSizePrefixedRootAsAgentSuiteTestResult = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentSuiteTestResult).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentSuiteTestResult.prototype.name = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentSuiteTestResult.prototype.started = function() {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentSuiteTestResult.prototype.finished = function() {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentSuiteTestResult.prototype.duration = function() {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {io.testable.agent.TestState}
 */
io.testable.agent.AgentSuiteTestResult.prototype.state = function() {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? /** @type {io.testable.agent.TestState} */ (this.bb.readInt8(this.bb_pos + offset)) : io.testable.agent.TestState.Passed;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentSuiteTestResult.prototype.errorType = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentSuiteTestResult.prototype.error = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 16);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentSuiteTestResult.prototype.errorTrace = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 18);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {number} index
 * @param {io.testable.agent.AgentFile=} obj
 * @returns {io.testable.agent.AgentFile}
 */
io.testable.agent.AgentSuiteTestResult.prototype.screenshots = function(index, obj) {
  var offset = this.bb.__offset(this.bb_pos, 20);
  return offset ? (obj || new io.testable.agent.AgentFile).__init(this.bb.__indirect(this.bb.__vector(this.bb_pos + offset) + index * 4), this.bb) : null;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentSuiteTestResult.prototype.screenshotsLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 20);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentSuiteTestResult.prototype.uuid = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 22);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentSuiteTestResult.startAgentSuiteTestResult = function(builder) {
  builder.startObject(10);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} nameOffset
 */
io.testable.agent.AgentSuiteTestResult.addName = function(builder, nameOffset) {
  builder.addFieldOffset(0, nameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} started
 */
io.testable.agent.AgentSuiteTestResult.addStarted = function(builder, started) {
  builder.addFieldInt64(1, started, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} finished
 */
io.testable.agent.AgentSuiteTestResult.addFinished = function(builder, finished) {
  builder.addFieldInt64(2, finished, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} duration
 */
io.testable.agent.AgentSuiteTestResult.addDuration = function(builder, duration) {
  builder.addFieldInt64(3, duration, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {io.testable.agent.TestState} state
 */
io.testable.agent.AgentSuiteTestResult.addState = function(builder, state) {
  builder.addFieldInt8(4, state, io.testable.agent.TestState.Passed);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} errorTypeOffset
 */
io.testable.agent.AgentSuiteTestResult.addErrorType = function(builder, errorTypeOffset) {
  builder.addFieldOffset(5, errorTypeOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} errorOffset
 */
io.testable.agent.AgentSuiteTestResult.addError = function(builder, errorOffset) {
  builder.addFieldOffset(6, errorOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} errorTraceOffset
 */
io.testable.agent.AgentSuiteTestResult.addErrorTrace = function(builder, errorTraceOffset) {
  builder.addFieldOffset(7, errorTraceOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} screenshotsOffset
 */
io.testable.agent.AgentSuiteTestResult.addScreenshots = function(builder, screenshotsOffset) {
  builder.addFieldOffset(8, screenshotsOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentSuiteTestResult.createScreenshotsVector = function(builder, data) {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
io.testable.agent.AgentSuiteTestResult.startScreenshotsVector = function(builder, numElems) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} uuidOffset
 */
io.testable.agent.AgentSuiteTestResult.addUuid = function(builder, uuidOffset) {
  builder.addFieldOffset(9, uuidOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentSuiteTestResult.endAgentSuiteTestResult = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} nameOffset
 * @param {flatbuffers.Long} started
 * @param {flatbuffers.Long} finished
 * @param {flatbuffers.Long} duration
 * @param {io.testable.agent.TestState} state
 * @param {flatbuffers.Offset} errorTypeOffset
 * @param {flatbuffers.Offset} errorOffset
 * @param {flatbuffers.Offset} errorTraceOffset
 * @param {flatbuffers.Offset} screenshotsOffset
 * @param {flatbuffers.Offset} uuidOffset
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentSuiteTestResult.createAgentSuiteTestResult = function(builder, nameOffset, started, finished, duration, state, errorTypeOffset, errorOffset, errorTraceOffset, screenshotsOffset, uuidOffset) {
  io.testable.agent.AgentSuiteTestResult.startAgentSuiteTestResult(builder);
  io.testable.agent.AgentSuiteTestResult.addName(builder, nameOffset);
  io.testable.agent.AgentSuiteTestResult.addStarted(builder, started);
  io.testable.agent.AgentSuiteTestResult.addFinished(builder, finished);
  io.testable.agent.AgentSuiteTestResult.addDuration(builder, duration);
  io.testable.agent.AgentSuiteTestResult.addState(builder, state);
  io.testable.agent.AgentSuiteTestResult.addErrorType(builder, errorTypeOffset);
  io.testable.agent.AgentSuiteTestResult.addError(builder, errorOffset);
  io.testable.agent.AgentSuiteTestResult.addErrorTrace(builder, errorTraceOffset);
  io.testable.agent.AgentSuiteTestResult.addScreenshots(builder, screenshotsOffset);
  io.testable.agent.AgentSuiteTestResult.addUuid(builder, uuidOffset);
  return io.testable.agent.AgentSuiteTestResult.endAgentSuiteTestResult(builder);
}

/**
 * @constructor
 */
io.testable.agent.AssignedRegionInfo = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AssignedRegionInfo}
 */
io.testable.agent.AssignedRegionInfo.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AssignedRegionInfo=} obj
 * @returns {io.testable.agent.AssignedRegionInfo}
 */
io.testable.agent.AssignedRegionInfo.getRootAsAssignedRegionInfo = function(bb, obj) {
  return (obj || new io.testable.agent.AssignedRegionInfo).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AssignedRegionInfo=} obj
 * @returns {io.testable.agent.AssignedRegionInfo}
 */
io.testable.agent.AssignedRegionInfo.getSizePrefixedRootAsAssignedRegionInfo = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AssignedRegionInfo).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AssignedRegionInfo.prototype.regionName = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AssignedRegionInfo.prototype.regionDescription = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AssignedRegionInfo.prototype.providerId = function() {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AssignedRegionInfo.prototype.key = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AssignedRegionInfo.prototype.locationName = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AssignedRegionInfo.prototype.locationDescription = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AssignedRegionInfo.startAssignedRegionInfo = function(builder) {
  builder.startObject(6);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} regionNameOffset
 */
io.testable.agent.AssignedRegionInfo.addRegionName = function(builder, regionNameOffset) {
  builder.addFieldOffset(0, regionNameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} regionDescriptionOffset
 */
io.testable.agent.AssignedRegionInfo.addRegionDescription = function(builder, regionDescriptionOffset) {
  builder.addFieldOffset(1, regionDescriptionOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} providerId
 */
io.testable.agent.AssignedRegionInfo.addProviderId = function(builder, providerId) {
  builder.addFieldInt64(2, providerId, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} keyOffset
 */
io.testable.agent.AssignedRegionInfo.addKey = function(builder, keyOffset) {
  builder.addFieldOffset(3, keyOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} locationNameOffset
 */
io.testable.agent.AssignedRegionInfo.addLocationName = function(builder, locationNameOffset) {
  builder.addFieldOffset(4, locationNameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} locationDescriptionOffset
 */
io.testable.agent.AssignedRegionInfo.addLocationDescription = function(builder, locationDescriptionOffset) {
  builder.addFieldOffset(5, locationDescriptionOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AssignedRegionInfo.endAssignedRegionInfo = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} regionNameOffset
 * @param {flatbuffers.Offset} regionDescriptionOffset
 * @param {flatbuffers.Long} providerId
 * @param {flatbuffers.Offset} keyOffset
 * @param {flatbuffers.Offset} locationNameOffset
 * @param {flatbuffers.Offset} locationDescriptionOffset
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AssignedRegionInfo.createAssignedRegionInfo = function(builder, regionNameOffset, regionDescriptionOffset, providerId, keyOffset, locationNameOffset, locationDescriptionOffset) {
  io.testable.agent.AssignedRegionInfo.startAssignedRegionInfo(builder);
  io.testable.agent.AssignedRegionInfo.addRegionName(builder, regionNameOffset);
  io.testable.agent.AssignedRegionInfo.addRegionDescription(builder, regionDescriptionOffset);
  io.testable.agent.AssignedRegionInfo.addProviderId(builder, providerId);
  io.testable.agent.AssignedRegionInfo.addKey(builder, keyOffset);
  io.testable.agent.AssignedRegionInfo.addLocationName(builder, locationNameOffset);
  io.testable.agent.AssignedRegionInfo.addLocationDescription(builder, locationDescriptionOffset);
  return io.testable.agent.AssignedRegionInfo.endAssignedRegionInfo(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentStartSuiteResult = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentStartSuiteResult}
 */
io.testable.agent.AgentStartSuiteResult.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentStartSuiteResult=} obj
 * @returns {io.testable.agent.AgentStartSuiteResult}
 */
io.testable.agent.AgentStartSuiteResult.getRootAsAgentStartSuiteResult = function(bb, obj) {
  return (obj || new io.testable.agent.AgentStartSuiteResult).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentStartSuiteResult=} obj
 * @returns {io.testable.agent.AgentStartSuiteResult}
 */
io.testable.agent.AgentStartSuiteResult.getSizePrefixedRootAsAgentStartSuiteResult = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentStartSuiteResult).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStartSuiteResult.prototype.uuid = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStartSuiteResult.prototype.name = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentStartSuiteResult.prototype.started = function() {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentStartSuiteResult.prototype.concurrentClientIndex = function() {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentStartSuiteResult.prototype.globalConcurrentClientIndex = function() {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentStartSuiteResult.prototype.regionalConcurrentClientIndex = function() {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {number}
 */
io.testable.agent.AgentStartSuiteResult.prototype.iteration = function() {
  var offset = this.bb.__offset(this.bb_pos, 16);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStartSuiteResult.prototype.iterationId = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 18);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStartSuiteResult.prototype.threadId = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 20);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {io.testable.agent.Browser=} obj
 * @returns {io.testable.agent.Browser|null}
 */
io.testable.agent.AgentStartSuiteResult.prototype.browser = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 22);
  return offset ? (obj || new io.testable.agent.Browser).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStartSuiteResult.prototype.device = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 24);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentStartSuiteResult.startAgentStartSuiteResult = function(builder) {
  builder.startObject(11);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} uuidOffset
 */
io.testable.agent.AgentStartSuiteResult.addUuid = function(builder, uuidOffset) {
  builder.addFieldOffset(0, uuidOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} nameOffset
 */
io.testable.agent.AgentStartSuiteResult.addName = function(builder, nameOffset) {
  builder.addFieldOffset(1, nameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} started
 */
io.testable.agent.AgentStartSuiteResult.addStarted = function(builder, started) {
  builder.addFieldInt64(2, started, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} concurrentClientIndex
 */
io.testable.agent.AgentStartSuiteResult.addConcurrentClientIndex = function(builder, concurrentClientIndex) {
  builder.addFieldInt64(3, concurrentClientIndex, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} globalConcurrentClientIndex
 */
io.testable.agent.AgentStartSuiteResult.addGlobalConcurrentClientIndex = function(builder, globalConcurrentClientIndex) {
  builder.addFieldInt64(4, globalConcurrentClientIndex, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} regionalConcurrentClientIndex
 */
io.testable.agent.AgentStartSuiteResult.addRegionalConcurrentClientIndex = function(builder, regionalConcurrentClientIndex) {
  builder.addFieldInt64(5, regionalConcurrentClientIndex, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} iteration
 */
io.testable.agent.AgentStartSuiteResult.addIteration = function(builder, iteration) {
  builder.addFieldInt32(6, iteration, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} iterationIdOffset
 */
io.testable.agent.AgentStartSuiteResult.addIterationId = function(builder, iterationIdOffset) {
  builder.addFieldOffset(7, iterationIdOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} threadIdOffset
 */
io.testable.agent.AgentStartSuiteResult.addThreadId = function(builder, threadIdOffset) {
  builder.addFieldOffset(8, threadIdOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} browserOffset
 */
io.testable.agent.AgentStartSuiteResult.addBrowser = function(builder, browserOffset) {
  builder.addFieldOffset(9, browserOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} deviceOffset
 */
io.testable.agent.AgentStartSuiteResult.addDevice = function(builder, deviceOffset) {
  builder.addFieldOffset(10, deviceOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentStartSuiteResult.endAgentStartSuiteResult = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} uuidOffset
 * @param {flatbuffers.Offset} nameOffset
 * @param {flatbuffers.Long} started
 * @param {flatbuffers.Long} concurrentClientIndex
 * @param {flatbuffers.Long} globalConcurrentClientIndex
 * @param {flatbuffers.Long} regionalConcurrentClientIndex
 * @param {number} iteration
 * @param {flatbuffers.Offset} iterationIdOffset
 * @param {flatbuffers.Offset} threadIdOffset
 * @param {flatbuffers.Offset} browserOffset
 * @param {flatbuffers.Offset} deviceOffset
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentStartSuiteResult.createAgentStartSuiteResult = function(builder, uuidOffset, nameOffset, started, concurrentClientIndex, globalConcurrentClientIndex, regionalConcurrentClientIndex, iteration, iterationIdOffset, threadIdOffset, browserOffset, deviceOffset) {
  io.testable.agent.AgentStartSuiteResult.startAgentStartSuiteResult(builder);
  io.testable.agent.AgentStartSuiteResult.addUuid(builder, uuidOffset);
  io.testable.agent.AgentStartSuiteResult.addName(builder, nameOffset);
  io.testable.agent.AgentStartSuiteResult.addStarted(builder, started);
  io.testable.agent.AgentStartSuiteResult.addConcurrentClientIndex(builder, concurrentClientIndex);
  io.testable.agent.AgentStartSuiteResult.addGlobalConcurrentClientIndex(builder, globalConcurrentClientIndex);
  io.testable.agent.AgentStartSuiteResult.addRegionalConcurrentClientIndex(builder, regionalConcurrentClientIndex);
  io.testable.agent.AgentStartSuiteResult.addIteration(builder, iteration);
  io.testable.agent.AgentStartSuiteResult.addIterationId(builder, iterationIdOffset);
  io.testable.agent.AgentStartSuiteResult.addThreadId(builder, threadIdOffset);
  io.testable.agent.AgentStartSuiteResult.addBrowser(builder, browserOffset);
  io.testable.agent.AgentStartSuiteResult.addDevice(builder, deviceOffset);
  return io.testable.agent.AgentStartSuiteResult.endAgentStartSuiteResult(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentFinishSuiteResult = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentFinishSuiteResult}
 */
io.testable.agent.AgentFinishSuiteResult.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentFinishSuiteResult=} obj
 * @returns {io.testable.agent.AgentFinishSuiteResult}
 */
io.testable.agent.AgentFinishSuiteResult.getRootAsAgentFinishSuiteResult = function(bb, obj) {
  return (obj || new io.testable.agent.AgentFinishSuiteResult).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentFinishSuiteResult=} obj
 * @returns {io.testable.agent.AgentFinishSuiteResult}
 */
io.testable.agent.AgentFinishSuiteResult.getSizePrefixedRootAsAgentFinishSuiteResult = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentFinishSuiteResult).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFinishSuiteResult.prototype.uuid = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFinishSuiteResult.prototype.name = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentFinishSuiteResult.prototype.finished = function() {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentFinishSuiteResult.prototype.duration = function() {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {boolean}
 */
io.testable.agent.AgentFinishSuiteResult.prototype.hasError = function() {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? !!this.bb.readInt8(this.bb_pos + offset) : false;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentFinishSuiteResult.startAgentFinishSuiteResult = function(builder) {
  builder.startObject(5);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} uuidOffset
 */
io.testable.agent.AgentFinishSuiteResult.addUuid = function(builder, uuidOffset) {
  builder.addFieldOffset(0, uuidOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} nameOffset
 */
io.testable.agent.AgentFinishSuiteResult.addName = function(builder, nameOffset) {
  builder.addFieldOffset(1, nameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} finished
 */
io.testable.agent.AgentFinishSuiteResult.addFinished = function(builder, finished) {
  builder.addFieldInt64(2, finished, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} duration
 */
io.testable.agent.AgentFinishSuiteResult.addDuration = function(builder, duration) {
  builder.addFieldInt64(3, duration, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {boolean} hasError
 */
io.testable.agent.AgentFinishSuiteResult.addHasError = function(builder, hasError) {
  builder.addFieldInt8(4, +hasError, +false);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentFinishSuiteResult.endAgentFinishSuiteResult = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} uuidOffset
 * @param {flatbuffers.Offset} nameOffset
 * @param {flatbuffers.Long} finished
 * @param {flatbuffers.Long} duration
 * @param {boolean} hasError
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentFinishSuiteResult.createAgentFinishSuiteResult = function(builder, uuidOffset, nameOffset, finished, duration, hasError) {
  io.testable.agent.AgentFinishSuiteResult.startAgentFinishSuiteResult(builder);
  io.testable.agent.AgentFinishSuiteResult.addUuid(builder, uuidOffset);
  io.testable.agent.AgentFinishSuiteResult.addName(builder, nameOffset);
  io.testable.agent.AgentFinishSuiteResult.addFinished(builder, finished);
  io.testable.agent.AgentFinishSuiteResult.addDuration(builder, duration);
  io.testable.agent.AgentFinishSuiteResult.addHasError(builder, hasError);
  return io.testable.agent.AgentFinishSuiteResult.endAgentFinishSuiteResult(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentStartSuiteTestResult = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentStartSuiteTestResult}
 */
io.testable.agent.AgentStartSuiteTestResult.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentStartSuiteTestResult=} obj
 * @returns {io.testable.agent.AgentStartSuiteTestResult}
 */
io.testable.agent.AgentStartSuiteTestResult.getRootAsAgentStartSuiteTestResult = function(bb, obj) {
  return (obj || new io.testable.agent.AgentStartSuiteTestResult).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentStartSuiteTestResult=} obj
 * @returns {io.testable.agent.AgentStartSuiteTestResult}
 */
io.testable.agent.AgentStartSuiteTestResult.getSizePrefixedRootAsAgentStartSuiteTestResult = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentStartSuiteTestResult).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStartSuiteTestResult.prototype.suiteUuid = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStartSuiteTestResult.prototype.suiteName = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStartSuiteTestResult.prototype.uuid = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStartSuiteTestResult.prototype.name = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentStartSuiteTestResult.prototype.started = function() {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentStartSuiteTestResult.startAgentStartSuiteTestResult = function(builder) {
  builder.startObject(5);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} suiteUuidOffset
 */
io.testable.agent.AgentStartSuiteTestResult.addSuiteUuid = function(builder, suiteUuidOffset) {
  builder.addFieldOffset(0, suiteUuidOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} suiteNameOffset
 */
io.testable.agent.AgentStartSuiteTestResult.addSuiteName = function(builder, suiteNameOffset) {
  builder.addFieldOffset(1, suiteNameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} uuidOffset
 */
io.testable.agent.AgentStartSuiteTestResult.addUuid = function(builder, uuidOffset) {
  builder.addFieldOffset(2, uuidOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} nameOffset
 */
io.testable.agent.AgentStartSuiteTestResult.addName = function(builder, nameOffset) {
  builder.addFieldOffset(3, nameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} started
 */
io.testable.agent.AgentStartSuiteTestResult.addStarted = function(builder, started) {
  builder.addFieldInt64(4, started, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentStartSuiteTestResult.endAgentStartSuiteTestResult = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} suiteUuidOffset
 * @param {flatbuffers.Offset} suiteNameOffset
 * @param {flatbuffers.Offset} uuidOffset
 * @param {flatbuffers.Offset} nameOffset
 * @param {flatbuffers.Long} started
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentStartSuiteTestResult.createAgentStartSuiteTestResult = function(builder, suiteUuidOffset, suiteNameOffset, uuidOffset, nameOffset, started) {
  io.testable.agent.AgentStartSuiteTestResult.startAgentStartSuiteTestResult(builder);
  io.testable.agent.AgentStartSuiteTestResult.addSuiteUuid(builder, suiteUuidOffset);
  io.testable.agent.AgentStartSuiteTestResult.addSuiteName(builder, suiteNameOffset);
  io.testable.agent.AgentStartSuiteTestResult.addUuid(builder, uuidOffset);
  io.testable.agent.AgentStartSuiteTestResult.addName(builder, nameOffset);
  io.testable.agent.AgentStartSuiteTestResult.addStarted(builder, started);
  return io.testable.agent.AgentStartSuiteTestResult.endAgentStartSuiteTestResult(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentFinishSuiteTestResult = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentFinishSuiteTestResult}
 */
io.testable.agent.AgentFinishSuiteTestResult.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentFinishSuiteTestResult=} obj
 * @returns {io.testable.agent.AgentFinishSuiteTestResult}
 */
io.testable.agent.AgentFinishSuiteTestResult.getRootAsAgentFinishSuiteTestResult = function(bb, obj) {
  return (obj || new io.testable.agent.AgentFinishSuiteTestResult).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentFinishSuiteTestResult=} obj
 * @returns {io.testable.agent.AgentFinishSuiteTestResult}
 */
io.testable.agent.AgentFinishSuiteTestResult.getSizePrefixedRootAsAgentFinishSuiteTestResult = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentFinishSuiteTestResult).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFinishSuiteTestResult.prototype.suiteUuid = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFinishSuiteTestResult.prototype.suiteName = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFinishSuiteTestResult.prototype.uuid = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFinishSuiteTestResult.prototype.name = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentFinishSuiteTestResult.prototype.finished = function() {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentFinishSuiteTestResult.prototype.duration = function() {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {io.testable.agent.TestState}
 */
io.testable.agent.AgentFinishSuiteTestResult.prototype.state = function() {
  var offset = this.bb.__offset(this.bb_pos, 16);
  return offset ? /** @type {io.testable.agent.TestState} */ (this.bb.readInt8(this.bb_pos + offset)) : io.testable.agent.TestState.Passed;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFinishSuiteTestResult.prototype.errorType = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 18);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFinishSuiteTestResult.prototype.error = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 20);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFinishSuiteTestResult.prototype.errorTrace = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 22);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {number} index
 * @param {io.testable.agent.AgentFile=} obj
 * @returns {io.testable.agent.AgentFile}
 */
io.testable.agent.AgentFinishSuiteTestResult.prototype.screenshots = function(index, obj) {
  var offset = this.bb.__offset(this.bb_pos, 24);
  return offset ? (obj || new io.testable.agent.AgentFile).__init(this.bb.__indirect(this.bb.__vector(this.bb_pos + offset) + index * 4), this.bb) : null;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentFinishSuiteTestResult.prototype.screenshotsLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 24);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentFinishSuiteTestResult.startAgentFinishSuiteTestResult = function(builder) {
  builder.startObject(11);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} suiteUuidOffset
 */
io.testable.agent.AgentFinishSuiteTestResult.addSuiteUuid = function(builder, suiteUuidOffset) {
  builder.addFieldOffset(0, suiteUuidOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} suiteNameOffset
 */
io.testable.agent.AgentFinishSuiteTestResult.addSuiteName = function(builder, suiteNameOffset) {
  builder.addFieldOffset(1, suiteNameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} uuidOffset
 */
io.testable.agent.AgentFinishSuiteTestResult.addUuid = function(builder, uuidOffset) {
  builder.addFieldOffset(2, uuidOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} nameOffset
 */
io.testable.agent.AgentFinishSuiteTestResult.addName = function(builder, nameOffset) {
  builder.addFieldOffset(3, nameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} finished
 */
io.testable.agent.AgentFinishSuiteTestResult.addFinished = function(builder, finished) {
  builder.addFieldInt64(4, finished, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} duration
 */
io.testable.agent.AgentFinishSuiteTestResult.addDuration = function(builder, duration) {
  builder.addFieldInt64(5, duration, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {io.testable.agent.TestState} state
 */
io.testable.agent.AgentFinishSuiteTestResult.addState = function(builder, state) {
  builder.addFieldInt8(6, state, io.testable.agent.TestState.Passed);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} errorTypeOffset
 */
io.testable.agent.AgentFinishSuiteTestResult.addErrorType = function(builder, errorTypeOffset) {
  builder.addFieldOffset(7, errorTypeOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} errorOffset
 */
io.testable.agent.AgentFinishSuiteTestResult.addError = function(builder, errorOffset) {
  builder.addFieldOffset(8, errorOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} errorTraceOffset
 */
io.testable.agent.AgentFinishSuiteTestResult.addErrorTrace = function(builder, errorTraceOffset) {
  builder.addFieldOffset(9, errorTraceOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} screenshotsOffset
 */
io.testable.agent.AgentFinishSuiteTestResult.addScreenshots = function(builder, screenshotsOffset) {
  builder.addFieldOffset(10, screenshotsOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentFinishSuiteTestResult.createScreenshotsVector = function(builder, data) {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
io.testable.agent.AgentFinishSuiteTestResult.startScreenshotsVector = function(builder, numElems) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentFinishSuiteTestResult.endAgentFinishSuiteTestResult = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} suiteUuidOffset
 * @param {flatbuffers.Offset} suiteNameOffset
 * @param {flatbuffers.Offset} uuidOffset
 * @param {flatbuffers.Offset} nameOffset
 * @param {flatbuffers.Long} finished
 * @param {flatbuffers.Long} duration
 * @param {io.testable.agent.TestState} state
 * @param {flatbuffers.Offset} errorTypeOffset
 * @param {flatbuffers.Offset} errorOffset
 * @param {flatbuffers.Offset} errorTraceOffset
 * @param {flatbuffers.Offset} screenshotsOffset
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentFinishSuiteTestResult.createAgentFinishSuiteTestResult = function(builder, suiteUuidOffset, suiteNameOffset, uuidOffset, nameOffset, finished, duration, state, errorTypeOffset, errorOffset, errorTraceOffset, screenshotsOffset) {
  io.testable.agent.AgentFinishSuiteTestResult.startAgentFinishSuiteTestResult(builder);
  io.testable.agent.AgentFinishSuiteTestResult.addSuiteUuid(builder, suiteUuidOffset);
  io.testable.agent.AgentFinishSuiteTestResult.addSuiteName(builder, suiteNameOffset);
  io.testable.agent.AgentFinishSuiteTestResult.addUuid(builder, uuidOffset);
  io.testable.agent.AgentFinishSuiteTestResult.addName(builder, nameOffset);
  io.testable.agent.AgentFinishSuiteTestResult.addFinished(builder, finished);
  io.testable.agent.AgentFinishSuiteTestResult.addDuration(builder, duration);
  io.testable.agent.AgentFinishSuiteTestResult.addState(builder, state);
  io.testable.agent.AgentFinishSuiteTestResult.addErrorType(builder, errorTypeOffset);
  io.testable.agent.AgentFinishSuiteTestResult.addError(builder, errorOffset);
  io.testable.agent.AgentFinishSuiteTestResult.addErrorTrace(builder, errorTraceOffset);
  io.testable.agent.AgentFinishSuiteTestResult.addScreenshots(builder, screenshotsOffset);
  return io.testable.agent.AgentFinishSuiteTestResult.endAgentFinishSuiteTestResult(builder);
}

/**
 * @constructor
 */
io.testable.agent.Browser = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.Browser}
 */
io.testable.agent.Browser.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.Browser=} obj
 * @returns {io.testable.agent.Browser}
 */
io.testable.agent.Browser.getRootAsBrowser = function(bb, obj) {
  return (obj || new io.testable.agent.Browser).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.Browser=} obj
 * @returns {io.testable.agent.Browser}
 */
io.testable.agent.Browser.getSizePrefixedRootAsBrowser = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.Browser).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.Browser.prototype.browserType = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.Browser.prototype.version = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.Browser.startBrowser = function(builder) {
  builder.startObject(2);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} browserTypeOffset
 */
io.testable.agent.Browser.addBrowserType = function(builder, browserTypeOffset) {
  builder.addFieldOffset(0, browserTypeOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} versionOffset
 */
io.testable.agent.Browser.addVersion = function(builder, versionOffset) {
  builder.addFieldOffset(1, versionOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.Browser.endBrowser = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} browserTypeOffset
 * @param {flatbuffers.Offset} versionOffset
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.Browser.createBrowser = function(builder, browserTypeOffset, versionOffset) {
  io.testable.agent.Browser.startBrowser(builder);
  io.testable.agent.Browser.addBrowserType(builder, browserTypeOffset);
  io.testable.agent.Browser.addVersion(builder, versionOffset);
  return io.testable.agent.Browser.endBrowser(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentVncInfo = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentVncInfo}
 */
io.testable.agent.AgentVncInfo.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentVncInfo=} obj
 * @returns {io.testable.agent.AgentVncInfo}
 */
io.testable.agent.AgentVncInfo.getRootAsAgentVncInfo = function(bb, obj) {
  return (obj || new io.testable.agent.AgentVncInfo).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentVncInfo=} obj
 * @returns {io.testable.agent.AgentVncInfo}
 */
io.testable.agent.AgentVncInfo.getSizePrefixedRootAsAgentVncInfo = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentVncInfo).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentVncInfo.prototype.id = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentVncInfo.prototype.execution = function() {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentVncInfo.prototype.chunk = function() {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentVncInfo.prototype.globalConcurrentClientIndex = function() {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentVncInfo.prototype.error = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentVncInfo.prototype.session = function() {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentVncInfo.startAgentVncInfo = function(builder) {
  builder.startObject(6);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} idOffset
 */
io.testable.agent.AgentVncInfo.addId = function(builder, idOffset) {
  builder.addFieldOffset(0, idOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} execution
 */
io.testable.agent.AgentVncInfo.addExecution = function(builder, execution) {
  builder.addFieldInt64(1, execution, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} chunk
 */
io.testable.agent.AgentVncInfo.addChunk = function(builder, chunk) {
  builder.addFieldInt64(2, chunk, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} globalConcurrentClientIndex
 */
io.testable.agent.AgentVncInfo.addGlobalConcurrentClientIndex = function(builder, globalConcurrentClientIndex) {
  builder.addFieldInt64(3, globalConcurrentClientIndex, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} errorOffset
 */
io.testable.agent.AgentVncInfo.addError = function(builder, errorOffset) {
  builder.addFieldOffset(4, errorOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} session
 */
io.testable.agent.AgentVncInfo.addSession = function(builder, session) {
  builder.addFieldInt32(5, session, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentVncInfo.endAgentVncInfo = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} idOffset
 * @param {flatbuffers.Long} execution
 * @param {flatbuffers.Long} chunk
 * @param {flatbuffers.Long} globalConcurrentClientIndex
 * @param {flatbuffers.Offset} errorOffset
 * @param {number} session
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentVncInfo.createAgentVncInfo = function(builder, idOffset, execution, chunk, globalConcurrentClientIndex, errorOffset, session) {
  io.testable.agent.AgentVncInfo.startAgentVncInfo(builder);
  io.testable.agent.AgentVncInfo.addId(builder, idOffset);
  io.testable.agent.AgentVncInfo.addExecution(builder, execution);
  io.testable.agent.AgentVncInfo.addChunk(builder, chunk);
  io.testable.agent.AgentVncInfo.addGlobalConcurrentClientIndex(builder, globalConcurrentClientIndex);
  io.testable.agent.AgentVncInfo.addError(builder, errorOffset);
  io.testable.agent.AgentVncInfo.addSession(builder, session);
  return io.testable.agent.AgentVncInfo.endAgentVncInfo(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentCommandAction = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentCommandAction}
 */
io.testable.agent.AgentCommandAction.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentCommandAction=} obj
 * @returns {io.testable.agent.AgentCommandAction}
 */
io.testable.agent.AgentCommandAction.getRootAsAgentCommandAction = function(bb, obj) {
  return (obj || new io.testable.agent.AgentCommandAction).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentCommandAction=} obj
 * @returns {io.testable.agent.AgentCommandAction}
 */
io.testable.agent.AgentCommandAction.getSizePrefixedRootAsAgentCommandAction = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentCommandAction).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {io.testable.agent.AgentCommandActionType}
 */
io.testable.agent.AgentCommandAction.prototype.type = function() {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? /** @type {io.testable.agent.AgentCommandActionType} */ (this.bb.readInt8(this.bb_pos + offset)) : io.testable.agent.AgentCommandActionType.StartStream;
};

/**
 * @param {io.testable.agent.AgentStartCommandStream=} obj
 * @returns {io.testable.agent.AgentStartCommandStream|null}
 */
io.testable.agent.AgentCommandAction.prototype.startStream = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? (obj || new io.testable.agent.AgentStartCommandStream).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {io.testable.agent.AgentFinishCommandStream=} obj
 * @returns {io.testable.agent.AgentFinishCommandStream|null}
 */
io.testable.agent.AgentCommandAction.prototype.finishStream = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? (obj || new io.testable.agent.AgentFinishCommandStream).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {io.testable.agent.AgentStartCommand=} obj
 * @returns {io.testable.agent.AgentStartCommand|null}
 */
io.testable.agent.AgentCommandAction.prototype.startCommand = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? (obj || new io.testable.agent.AgentStartCommand).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {io.testable.agent.AgentFinishCommand=} obj
 * @returns {io.testable.agent.AgentFinishCommand|null}
 */
io.testable.agent.AgentCommandAction.prototype.finishCommand = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? (obj || new io.testable.agent.AgentFinishCommand).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentCommandAction.startAgentCommandAction = function(builder) {
  builder.startObject(5);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {io.testable.agent.AgentCommandActionType} type
 */
io.testable.agent.AgentCommandAction.addType = function(builder, type) {
  builder.addFieldInt8(0, type, io.testable.agent.AgentCommandActionType.StartStream);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} startStreamOffset
 */
io.testable.agent.AgentCommandAction.addStartStream = function(builder, startStreamOffset) {
  builder.addFieldOffset(1, startStreamOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} finishStreamOffset
 */
io.testable.agent.AgentCommandAction.addFinishStream = function(builder, finishStreamOffset) {
  builder.addFieldOffset(2, finishStreamOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} startCommandOffset
 */
io.testable.agent.AgentCommandAction.addStartCommand = function(builder, startCommandOffset) {
  builder.addFieldOffset(3, startCommandOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} finishCommandOffset
 */
io.testable.agent.AgentCommandAction.addFinishCommand = function(builder, finishCommandOffset) {
  builder.addFieldOffset(4, finishCommandOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentCommandAction.endAgentCommandAction = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {io.testable.agent.AgentCommandActionType} type
 * @param {flatbuffers.Offset} startStreamOffset
 * @param {flatbuffers.Offset} finishStreamOffset
 * @param {flatbuffers.Offset} startCommandOffset
 * @param {flatbuffers.Offset} finishCommandOffset
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentCommandAction.createAgentCommandAction = function(builder, type, startStreamOffset, finishStreamOffset, startCommandOffset, finishCommandOffset) {
  io.testable.agent.AgentCommandAction.startAgentCommandAction(builder);
  io.testable.agent.AgentCommandAction.addType(builder, type);
  io.testable.agent.AgentCommandAction.addStartStream(builder, startStreamOffset);
  io.testable.agent.AgentCommandAction.addFinishStream(builder, finishStreamOffset);
  io.testable.agent.AgentCommandAction.addStartCommand(builder, startCommandOffset);
  io.testable.agent.AgentCommandAction.addFinishCommand(builder, finishCommandOffset);
  return io.testable.agent.AgentCommandAction.endAgentCommandAction(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentStartCommandStream = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentStartCommandStream}
 */
io.testable.agent.AgentStartCommandStream.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentStartCommandStream=} obj
 * @returns {io.testable.agent.AgentStartCommandStream}
 */
io.testable.agent.AgentStartCommandStream.getRootAsAgentStartCommandStream = function(bb, obj) {
  return (obj || new io.testable.agent.AgentStartCommandStream).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentStartCommandStream=} obj
 * @returns {io.testable.agent.AgentStartCommandStream}
 */
io.testable.agent.AgentStartCommandStream.getSizePrefixedRootAsAgentStartCommandStream = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentStartCommandStream).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStartCommandStream.prototype.id = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentStartCommandStream.prototype.started = function() {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentStartCommandStream.prototype.concurrentClientIndex = function() {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentStartCommandStream.prototype.globalConcurrentClientIndex = function() {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentStartCommandStream.prototype.regionalConcurrentClientIndex = function() {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {number}
 */
io.testable.agent.AgentStartCommandStream.prototype.iteration = function() {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStartCommandStream.prototype.iterationId = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 16);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStartCommandStream.prototype.threadId = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 18);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {io.testable.agent.Browser=} obj
 * @returns {io.testable.agent.Browser|null}
 */
io.testable.agent.AgentStartCommandStream.prototype.browser = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 20);
  return offset ? (obj || new io.testable.agent.Browser).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStartCommandStream.prototype.device = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 22);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentStartCommandStream.prototype.sessionNum = function() {
  var offset = this.bb.__offset(this.bb_pos, 24);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentStartCommandStream.startAgentStartCommandStream = function(builder) {
  builder.startObject(11);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} idOffset
 */
io.testable.agent.AgentStartCommandStream.addId = function(builder, idOffset) {
  builder.addFieldOffset(0, idOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} started
 */
io.testable.agent.AgentStartCommandStream.addStarted = function(builder, started) {
  builder.addFieldInt64(1, started, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} concurrentClientIndex
 */
io.testable.agent.AgentStartCommandStream.addConcurrentClientIndex = function(builder, concurrentClientIndex) {
  builder.addFieldInt64(2, concurrentClientIndex, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} globalConcurrentClientIndex
 */
io.testable.agent.AgentStartCommandStream.addGlobalConcurrentClientIndex = function(builder, globalConcurrentClientIndex) {
  builder.addFieldInt64(3, globalConcurrentClientIndex, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} regionalConcurrentClientIndex
 */
io.testable.agent.AgentStartCommandStream.addRegionalConcurrentClientIndex = function(builder, regionalConcurrentClientIndex) {
  builder.addFieldInt64(4, regionalConcurrentClientIndex, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} iteration
 */
io.testable.agent.AgentStartCommandStream.addIteration = function(builder, iteration) {
  builder.addFieldInt32(5, iteration, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} iterationIdOffset
 */
io.testable.agent.AgentStartCommandStream.addIterationId = function(builder, iterationIdOffset) {
  builder.addFieldOffset(6, iterationIdOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} threadIdOffset
 */
io.testable.agent.AgentStartCommandStream.addThreadId = function(builder, threadIdOffset) {
  builder.addFieldOffset(7, threadIdOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} browserOffset
 */
io.testable.agent.AgentStartCommandStream.addBrowser = function(builder, browserOffset) {
  builder.addFieldOffset(8, browserOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} deviceOffset
 */
io.testable.agent.AgentStartCommandStream.addDevice = function(builder, deviceOffset) {
  builder.addFieldOffset(9, deviceOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} sessionNum
 */
io.testable.agent.AgentStartCommandStream.addSessionNum = function(builder, sessionNum) {
  builder.addFieldInt32(10, sessionNum, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentStartCommandStream.endAgentStartCommandStream = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} idOffset
 * @param {flatbuffers.Long} started
 * @param {flatbuffers.Long} concurrentClientIndex
 * @param {flatbuffers.Long} globalConcurrentClientIndex
 * @param {flatbuffers.Long} regionalConcurrentClientIndex
 * @param {number} iteration
 * @param {flatbuffers.Offset} iterationIdOffset
 * @param {flatbuffers.Offset} threadIdOffset
 * @param {flatbuffers.Offset} browserOffset
 * @param {flatbuffers.Offset} deviceOffset
 * @param {number} sessionNum
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentStartCommandStream.createAgentStartCommandStream = function(builder, idOffset, started, concurrentClientIndex, globalConcurrentClientIndex, regionalConcurrentClientIndex, iteration, iterationIdOffset, threadIdOffset, browserOffset, deviceOffset, sessionNum) {
  io.testable.agent.AgentStartCommandStream.startAgentStartCommandStream(builder);
  io.testable.agent.AgentStartCommandStream.addId(builder, idOffset);
  io.testable.agent.AgentStartCommandStream.addStarted(builder, started);
  io.testable.agent.AgentStartCommandStream.addConcurrentClientIndex(builder, concurrentClientIndex);
  io.testable.agent.AgentStartCommandStream.addGlobalConcurrentClientIndex(builder, globalConcurrentClientIndex);
  io.testable.agent.AgentStartCommandStream.addRegionalConcurrentClientIndex(builder, regionalConcurrentClientIndex);
  io.testable.agent.AgentStartCommandStream.addIteration(builder, iteration);
  io.testable.agent.AgentStartCommandStream.addIterationId(builder, iterationIdOffset);
  io.testable.agent.AgentStartCommandStream.addThreadId(builder, threadIdOffset);
  io.testable.agent.AgentStartCommandStream.addBrowser(builder, browserOffset);
  io.testable.agent.AgentStartCommandStream.addDevice(builder, deviceOffset);
  io.testable.agent.AgentStartCommandStream.addSessionNum(builder, sessionNum);
  return io.testable.agent.AgentStartCommandStream.endAgentStartCommandStream(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentFinishCommandStream = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentFinishCommandStream}
 */
io.testable.agent.AgentFinishCommandStream.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentFinishCommandStream=} obj
 * @returns {io.testable.agent.AgentFinishCommandStream}
 */
io.testable.agent.AgentFinishCommandStream.getRootAsAgentFinishCommandStream = function(bb, obj) {
  return (obj || new io.testable.agent.AgentFinishCommandStream).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentFinishCommandStream=} obj
 * @returns {io.testable.agent.AgentFinishCommandStream}
 */
io.testable.agent.AgentFinishCommandStream.getSizePrefixedRootAsAgentFinishCommandStream = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentFinishCommandStream).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFinishCommandStream.prototype.id = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentFinishCommandStream.prototype.finished = function() {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentFinishCommandStream.prototype.duration = function() {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {boolean}
 */
io.testable.agent.AgentFinishCommandStream.prototype.hasError = function() {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? !!this.bb.readInt8(this.bb_pos + offset) : false;
};

/**
 * @param {io.testable.agent.Browser=} obj
 * @returns {io.testable.agent.Browser|null}
 */
io.testable.agent.AgentFinishCommandStream.prototype.browser = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? (obj || new io.testable.agent.Browser).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentFinishCommandStream.startAgentFinishCommandStream = function(builder) {
  builder.startObject(5);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} idOffset
 */
io.testable.agent.AgentFinishCommandStream.addId = function(builder, idOffset) {
  builder.addFieldOffset(0, idOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} finished
 */
io.testable.agent.AgentFinishCommandStream.addFinished = function(builder, finished) {
  builder.addFieldInt64(1, finished, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} duration
 */
io.testable.agent.AgentFinishCommandStream.addDuration = function(builder, duration) {
  builder.addFieldInt64(2, duration, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {boolean} hasError
 */
io.testable.agent.AgentFinishCommandStream.addHasError = function(builder, hasError) {
  builder.addFieldInt8(3, +hasError, +false);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} browserOffset
 */
io.testable.agent.AgentFinishCommandStream.addBrowser = function(builder, browserOffset) {
  builder.addFieldOffset(4, browserOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentFinishCommandStream.endAgentFinishCommandStream = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} idOffset
 * @param {flatbuffers.Long} finished
 * @param {flatbuffers.Long} duration
 * @param {boolean} hasError
 * @param {flatbuffers.Offset} browserOffset
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentFinishCommandStream.createAgentFinishCommandStream = function(builder, idOffset, finished, duration, hasError, browserOffset) {
  io.testable.agent.AgentFinishCommandStream.startAgentFinishCommandStream(builder);
  io.testable.agent.AgentFinishCommandStream.addId(builder, idOffset);
  io.testable.agent.AgentFinishCommandStream.addFinished(builder, finished);
  io.testable.agent.AgentFinishCommandStream.addDuration(builder, duration);
  io.testable.agent.AgentFinishCommandStream.addHasError(builder, hasError);
  io.testable.agent.AgentFinishCommandStream.addBrowser(builder, browserOffset);
  return io.testable.agent.AgentFinishCommandStream.endAgentFinishCommandStream(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentStartCommand = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentStartCommand}
 */
io.testable.agent.AgentStartCommand.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentStartCommand=} obj
 * @returns {io.testable.agent.AgentStartCommand}
 */
io.testable.agent.AgentStartCommand.getRootAsAgentStartCommand = function(bb, obj) {
  return (obj || new io.testable.agent.AgentStartCommand).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentStartCommand=} obj
 * @returns {io.testable.agent.AgentStartCommand}
 */
io.testable.agent.AgentStartCommand.getSizePrefixedRootAsAgentStartCommand = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentStartCommand).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStartCommand.prototype.id = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStartCommand.prototype.streamId = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {io.testable.agent.Browser=} obj
 * @returns {io.testable.agent.Browser|null}
 */
io.testable.agent.AgentStartCommand.prototype.browser = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? (obj || new io.testable.agent.Browser).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStartCommand.prototype.command = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStartCommand.prototype.name = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStartCommand.prototype.requestBody = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentStartCommand.prototype.params = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 16);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentStartCommand.prototype.started = function() {
  var offset = this.bb.__offset(this.bb_pos, 18);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentStartCommand.prototype.offset = function() {
  var offset = this.bb.__offset(this.bb_pos, 20);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {boolean}
 */
io.testable.agent.AgentStartCommand.prototype.detailed = function() {
  var offset = this.bb.__offset(this.bb_pos, 22);
  return offset ? !!this.bb.readInt8(this.bb_pos + offset) : false;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentStartCommand.prototype.videoOffset = function() {
  var offset = this.bb.__offset(this.bb_pos, 24);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentStartCommand.startAgentStartCommand = function(builder) {
  builder.startObject(11);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} idOffset
 */
io.testable.agent.AgentStartCommand.addId = function(builder, idOffset) {
  builder.addFieldOffset(0, idOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} streamIdOffset
 */
io.testable.agent.AgentStartCommand.addStreamId = function(builder, streamIdOffset) {
  builder.addFieldOffset(1, streamIdOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} browserOffset
 */
io.testable.agent.AgentStartCommand.addBrowser = function(builder, browserOffset) {
  builder.addFieldOffset(2, browserOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} commandOffset
 */
io.testable.agent.AgentStartCommand.addCommand = function(builder, commandOffset) {
  builder.addFieldOffset(3, commandOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} nameOffset
 */
io.testable.agent.AgentStartCommand.addName = function(builder, nameOffset) {
  builder.addFieldOffset(4, nameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} requestBodyOffset
 */
io.testable.agent.AgentStartCommand.addRequestBody = function(builder, requestBodyOffset) {
  builder.addFieldOffset(5, requestBodyOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} paramsOffset
 */
io.testable.agent.AgentStartCommand.addParams = function(builder, paramsOffset) {
  builder.addFieldOffset(6, paramsOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} started
 */
io.testable.agent.AgentStartCommand.addStarted = function(builder, started) {
  builder.addFieldInt64(7, started, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} offset
 */
io.testable.agent.AgentStartCommand.addOffset = function(builder, offset) {
  builder.addFieldInt64(8, offset, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {boolean} detailed
 */
io.testable.agent.AgentStartCommand.addDetailed = function(builder, detailed) {
  builder.addFieldInt8(9, +detailed, +false);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} videoOffset
 */
io.testable.agent.AgentStartCommand.addVideoOffset = function(builder, videoOffset) {
  builder.addFieldInt64(10, videoOffset, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentStartCommand.endAgentStartCommand = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} idOffset
 * @param {flatbuffers.Offset} streamIdOffset
 * @param {flatbuffers.Offset} browserOffset
 * @param {flatbuffers.Offset} commandOffset
 * @param {flatbuffers.Offset} nameOffset
 * @param {flatbuffers.Offset} requestBodyOffset
 * @param {flatbuffers.Offset} paramsOffset
 * @param {flatbuffers.Long} started
 * @param {flatbuffers.Long} offset
 * @param {boolean} detailed
 * @param {flatbuffers.Long} videoOffset
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentStartCommand.createAgentStartCommand = function(builder, idOffset, streamIdOffset, browserOffset, commandOffset, nameOffset, requestBodyOffset, paramsOffset, started, offset, detailed, videoOffset) {
  io.testable.agent.AgentStartCommand.startAgentStartCommand(builder);
  io.testable.agent.AgentStartCommand.addId(builder, idOffset);
  io.testable.agent.AgentStartCommand.addStreamId(builder, streamIdOffset);
  io.testable.agent.AgentStartCommand.addBrowser(builder, browserOffset);
  io.testable.agent.AgentStartCommand.addCommand(builder, commandOffset);
  io.testable.agent.AgentStartCommand.addName(builder, nameOffset);
  io.testable.agent.AgentStartCommand.addRequestBody(builder, requestBodyOffset);
  io.testable.agent.AgentStartCommand.addParams(builder, paramsOffset);
  io.testable.agent.AgentStartCommand.addStarted(builder, started);
  io.testable.agent.AgentStartCommand.addOffset(builder, offset);
  io.testable.agent.AgentStartCommand.addDetailed(builder, detailed);
  io.testable.agent.AgentStartCommand.addVideoOffset(builder, videoOffset);
  return io.testable.agent.AgentStartCommand.endAgentStartCommand(builder);
}

/**
 * @constructor
 */
io.testable.agent.AgentFinishCommand = function() {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  this.bb = null;

  /**
   * @type {number}
   */
  this.bb_pos = 0;
};

/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {io.testable.agent.AgentFinishCommand}
 */
io.testable.agent.AgentFinishCommand.prototype.__init = function(i, bb) {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentFinishCommand=} obj
 * @returns {io.testable.agent.AgentFinishCommand}
 */
io.testable.agent.AgentFinishCommand.getRootAsAgentFinishCommand = function(bb, obj) {
  return (obj || new io.testable.agent.AgentFinishCommand).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {io.testable.agent.AgentFinishCommand=} obj
 * @returns {io.testable.agent.AgentFinishCommand}
 */
io.testable.agent.AgentFinishCommand.getSizePrefixedRootAsAgentFinishCommand = function(bb, obj) {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new io.testable.agent.AgentFinishCommand).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFinishCommand.prototype.id = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFinishCommand.prototype.streamId = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {io.testable.agent.Browser=} obj
 * @returns {io.testable.agent.Browser|null}
 */
io.testable.agent.AgentFinishCommand.prototype.browser = function(obj) {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? (obj || new io.testable.agent.Browser).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFinishCommand.prototype.name = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFinishCommand.prototype.status = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFinishCommand.prototype.responseBody = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFinishCommand.prototype.params = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 16);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
io.testable.agent.AgentFinishCommand.prototype.error = function(optionalEncoding) {
  var offset = this.bb.__offset(this.bb_pos, 18);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @returns {boolean}
 */
io.testable.agent.AgentFinishCommand.prototype.success = function() {
  var offset = this.bb.__offset(this.bb_pos, 20);
  return offset ? !!this.bb.readInt8(this.bb_pos + offset) : false;
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentFinishCommand.prototype.finished = function() {
  var offset = this.bb.__offset(this.bb_pos, 22);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @returns {flatbuffers.Long}
 */
io.testable.agent.AgentFinishCommand.prototype.duration = function() {
  var offset = this.bb.__offset(this.bb_pos, 24);
  return offset ? this.bb.readInt64(this.bb_pos + offset) : this.bb.createLong(0, 0);
};

/**
 * @param {number} index
 * @param {io.testable.agent.AgentFile=} obj
 * @returns {io.testable.agent.AgentFile}
 */
io.testable.agent.AgentFinishCommand.prototype.files = function(index, obj) {
  var offset = this.bb.__offset(this.bb_pos, 26);
  return offset ? (obj || new io.testable.agent.AgentFile).__init(this.bb.__indirect(this.bb.__vector(this.bb_pos + offset) + index * 4), this.bb) : null;
};

/**
 * @returns {number}
 */
io.testable.agent.AgentFinishCommand.prototype.filesLength = function() {
  var offset = this.bb.__offset(this.bb_pos, 26);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Builder} builder
 */
io.testable.agent.AgentFinishCommand.startAgentFinishCommand = function(builder) {
  builder.startObject(12);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} idOffset
 */
io.testable.agent.AgentFinishCommand.addId = function(builder, idOffset) {
  builder.addFieldOffset(0, idOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} streamIdOffset
 */
io.testable.agent.AgentFinishCommand.addStreamId = function(builder, streamIdOffset) {
  builder.addFieldOffset(1, streamIdOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} browserOffset
 */
io.testable.agent.AgentFinishCommand.addBrowser = function(builder, browserOffset) {
  builder.addFieldOffset(2, browserOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} nameOffset
 */
io.testable.agent.AgentFinishCommand.addName = function(builder, nameOffset) {
  builder.addFieldOffset(3, nameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} statusOffset
 */
io.testable.agent.AgentFinishCommand.addStatus = function(builder, statusOffset) {
  builder.addFieldOffset(4, statusOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} responseBodyOffset
 */
io.testable.agent.AgentFinishCommand.addResponseBody = function(builder, responseBodyOffset) {
  builder.addFieldOffset(5, responseBodyOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} paramsOffset
 */
io.testable.agent.AgentFinishCommand.addParams = function(builder, paramsOffset) {
  builder.addFieldOffset(6, paramsOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} errorOffset
 */
io.testable.agent.AgentFinishCommand.addError = function(builder, errorOffset) {
  builder.addFieldOffset(7, errorOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {boolean} success
 */
io.testable.agent.AgentFinishCommand.addSuccess = function(builder, success) {
  builder.addFieldInt8(8, +success, +false);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} finished
 */
io.testable.agent.AgentFinishCommand.addFinished = function(builder, finished) {
  builder.addFieldInt64(9, finished, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} duration
 */
io.testable.agent.AgentFinishCommand.addDuration = function(builder, duration) {
  builder.addFieldInt64(10, duration, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} filesOffset
 */
io.testable.agent.AgentFinishCommand.addFiles = function(builder, filesOffset) {
  builder.addFieldOffset(11, filesOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentFinishCommand.createFilesVector = function(builder, data) {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
io.testable.agent.AgentFinishCommand.startFilesVector = function(builder, numElems) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentFinishCommand.endAgentFinishCommand = function(builder) {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} idOffset
 * @param {flatbuffers.Offset} streamIdOffset
 * @param {flatbuffers.Offset} browserOffset
 * @param {flatbuffers.Offset} nameOffset
 * @param {flatbuffers.Offset} statusOffset
 * @param {flatbuffers.Offset} responseBodyOffset
 * @param {flatbuffers.Offset} paramsOffset
 * @param {flatbuffers.Offset} errorOffset
 * @param {boolean} success
 * @param {flatbuffers.Long} finished
 * @param {flatbuffers.Long} duration
 * @param {flatbuffers.Offset} filesOffset
 * @returns {flatbuffers.Offset}
 */
io.testable.agent.AgentFinishCommand.createAgentFinishCommand = function(builder, idOffset, streamIdOffset, browserOffset, nameOffset, statusOffset, responseBodyOffset, paramsOffset, errorOffset, success, finished, duration, filesOffset) {
  io.testable.agent.AgentFinishCommand.startAgentFinishCommand(builder);
  io.testable.agent.AgentFinishCommand.addId(builder, idOffset);
  io.testable.agent.AgentFinishCommand.addStreamId(builder, streamIdOffset);
  io.testable.agent.AgentFinishCommand.addBrowser(builder, browserOffset);
  io.testable.agent.AgentFinishCommand.addName(builder, nameOffset);
  io.testable.agent.AgentFinishCommand.addStatus(builder, statusOffset);
  io.testable.agent.AgentFinishCommand.addResponseBody(builder, responseBodyOffset);
  io.testable.agent.AgentFinishCommand.addParams(builder, paramsOffset);
  io.testable.agent.AgentFinishCommand.addError(builder, errorOffset);
  io.testable.agent.AgentFinishCommand.addSuccess(builder, success);
  io.testable.agent.AgentFinishCommand.addFinished(builder, finished);
  io.testable.agent.AgentFinishCommand.addDuration(builder, duration);
  io.testable.agent.AgentFinishCommand.addFiles(builder, filesOffset);
  return io.testable.agent.AgentFinishCommand.endAgentFinishCommand(builder);
}

// Exports for Node.js and RequireJS
this.io = io;
